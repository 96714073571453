import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import { XHead, SubHead } from "./Style";
import { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { list } from "assets/list";
import { formatDate, amountFormat } from "utils/format";

const WaBox = styled(Stack)`
  /* position: relative; */
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 175px 25px 15px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const Desc = styled(Typography)`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      textAlign: "center",
    },
  })};
`;

const Desc2 = styled(Typography)`
  color: #c6c6c6;
  font-family: "Kind";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 50px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })};
`;

const ChartBox = styled(Stack)`
  .chart-box {
    width: 100%;
    height: 460px;
    ${({ theme }) => ({
      [theme.breakpoints.down("md")]: {
        height: "230px",
      },
    })};
  }
`;

const Chart = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const chartRef: any = useRef();
  const { ref, isShow } = useShow();
  const handleResizeChart = (myChart: any) => {
    myChart && myChart.resize();
  };
  const reDraw = (myChart: any) => {
    const option = myChart.getOption();
    const splitInfo = option.yAxis.reduce(
      (pre: any, next: any, index: any) => {
        const y = myChart.getModel().getComponent("yAxis", index).axis
          .scale._extent;
        const currentMin = y[0];
        const currentMax = y[1];
        const yInterVal = myChart.getModel().getComponent("yAxis", index).axis
          .scale._interval;
        const distance = currentMax - currentMin;
        const splitNumber = distance / yInterVal;
        if (index === 0 && splitNumber !== 0) {
          pre.splitNumber = splitNumber;
        } else if (
          pre.splitNumber !== 0 &&
          splitNumber !== 0 &&
          splitNumber !== pre.splitNumber
        ) {
          pre.needRedraw = true;
          const maxNum =
            Math.ceil(splitNumber / pre.splitNumber) *
            yInterVal *
            pre.splitNumber;
          next.min = currentMin;
          next.max = maxNum + currentMin;
          next.interval = Math.ceil(splitNumber / pre.splitNumber) * yInterVal;
        }
        return pre;
      },
      { splitNumber: 0, needRedraw: false }
    );
    if (splitInfo.needRedraw) {
      myChart.setOption(option, true);
    }
  };
  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const option = {
      color: ["#5acca0", "#e4c989"],
      grid: {
        containLabel: true,
        left: 30,
        right: 30,
        top: 30,
        bottom: 60,
      },
      tooltip: {
        trigger: "axis",
        // showContent: false
        backgroundColor: "rgba(51, 51, 60, 1)",
        borderColor: "rgba(90, 90, 91, 1)",
        padding: 20,
        className: "echarts-tooltip",
        textStyle: {
          color: "#FFFFFF",
        },
        // formatter: (params: any) => {
        //   const formatStr = 'DD MMM YYYY'
        //   return `
        //     <p style="font-family: 'Arboria-Book'; font-size: 16px;">${213}</p>
        //     <p style="font-family: 'Arboria-Medium'; font-size: 20px; margin-top: 12px;">
        //       <span style="display: inline-block; margin-right: 8px;background-color: #33333C;width:10px;height:10px;border-radius: 50%;border:3px solid #E4C989;"></span>
        //       ${params[0].value > 0 ? '≈' : ''} $${123}
        //     </p>
        //   `
        // }
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        offset: 10,
        data: list.Date,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: "#ffffff",
          fontFamily: "Arboria-Book",
          fontSize: 12,
          // formatter: (value: any) => {
          //   return formatDate(value)
          // }
        },
      },
      dataZoom: [
        {
          start: 0,
          end: 100,
          bottom: 10,
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Net Value",
          axisLabel: {
            color: "#ffffff",
            fontFamily: "Arboria-Book",
            fontSize: 12,
            // formatter: (value: any) => {
            //   return amountFormat(Number(value), 2)
            // }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#242426"],
              width: 1,
              dashOffset: 0,
              type: [10, 4],
            },
          },
        },
        {
          type: "value",
          name: "BTC Price",
          axisLabel: {
            color: "#ffffff",
            fontFamily: "Arboria-Book",
            fontSize: 12,
            // formatter: (value: any) => {
            //   return amountFormat(Number(value), 2)
            // }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#242426"],
              width: 1,
              dashOffset: 0,
              type: [10, 4],
            },
          },
        },
      ],
      series: [
        {
          data: list.NetValue,
          type: "line",
          name: "Net Value",
          yAxisIndex: 0,
          showSymbol: false,
          lineStyle: {
            color: "#5acca0",
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(90, 204, 160, 0.5)",
              },
              {
                offset: 1,
                color: "rgba(90, 204, 160, 0.3)",
              },
            ]),
          },
        },
        {
          data: list.Price,
          type: "line",
          name: "BTC Price",
          showSymbol: false,
          yAxisIndex: 1,
          lineStyle: {
            color: "rgb(247, 161, 57)",
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(247, 161, 57, 0.5)",
              },
              {
                offset: 1,
                color: "rgba(247, 161, 57, 0.3)",
              },
            ]),
          },
        },
      ],
    };
    myChart.setOption(option);
    reDraw(myChart);
    window.addEventListener("resize", () => handleResizeChart(myChart));
    return () => {
      window.removeEventListener("resize", () => handleResizeChart(myChart));
      myChart.dispose();
    };
  }, []);
  return (
    <WaBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <XHead>
            X <span>for higher yield</span>
          </XHead>
        </Stack>
        <Desc mt={45}>
          Achieve high yields in a bull market while maintaining stable returns
          through bear market with our innovative arbitrage strategy. This
          approach combines the dynamic growth potential of a bullish market
          with the reliability of steady returns, ensuring both growth and
          stability for your investments, enhanced by alphas from our token and
          DeFi Protocol rewards.
        </Desc>
        <ChartBox mt={70}>
          <div className="chart-box" ref={chartRef} />
        </ChartBox>
        <Desc2 mt={15}>
          Imagine this: if you had invested just 1 USDX back on June 6, 2020, by
          staking it to sUSDX, you could now cash out 2.9099 USDX as of
          September 30, 2024, before any protocol charges. That's not just
          growth; that's an impressive 28% APY maintained over four years! Your
          investment would have essentially tripled, showcasing the powerful
          potential of staking with this strategy. This is even before any
          additional rewarding yield by the protocol and other DeFi farming
          yield.
        </Desc2>
      </Containter>
    </WaBox>
  );
};

export default Chart;
