import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import USDX from "assets/images/token-usdx.png";
import SUSDX from "assets/images/token-susdx.png";
import Grid from "@mui/material/Unstable_Grid2";

const MeetBox = styled(Stack)`
  background: #fff;
`;

export const Head = styled(Typography)`
  text-align: center;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #1f7a55;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
  })};
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 180px 25px 200px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const TokenBox = styled(Stack)`
  border-radius: 40px;
  border: 1px solid #898989;
  padding: 40px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 200px;
  height: 200px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "120px",
      height: "120px",
    },
  })};
`;

export const TokenName = styled(Typography)`
  color: #0e0e0e;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })};
`;

export const Title = styled(Typography)`
  color: #0e0e0e;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  })};
`;

export const Info = styled(Typography)`
  color: #0e0e0e;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 100%;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })};
`;

const MeetButton = styled(Button)`
  border-radius: 66px;
  border: 1px solid #fff;
  background: #0e0e0e;
  color: #fff;
  width: 100%;
  &:hover {
    background: #0e0e0e;
    color: #fff;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "40px",
    },
  })};
`;

const MeetButtonOut = styled(Button)`
  border-radius: 58px;
  border: 1px solid #0e0e0e;
  color: #0e0e0e;
  width: 100%;
  &:hover {
    border: 1px solid #0e0e0e;
    color: #0e0e0e;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "40px",
      padding: "0 15px",
    },
  })};
`;

const Meet = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <MeetBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Head>Meet our products</Head>
        </Stack>
        <Grid
          mt={upToMd ? 150 : 40}
          container
          spacing={30}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid xs={12} md={6}>
            <TokenBox>
              <Stack
                mt={upToMd ? 65 : 25}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <ImgCon src={USDX} />
              </Stack>
              <TokenName mt={upToMd ? 35 : 25}>USDX</TokenName>
              <Title mt={upToMd ? 35 : 20}>A stablecoin</Title>
              <Info mt={upToMd ? 35 : 20}>
                USDX is a synthetic USD stablecoin built to connect DeFi, CeFi
                and TradFi. It offers a crypto-native stablecoin bankless
                solution which is censorship-resistant, scalable, and highly
                stable.
              </Info>
              <Stack
                mt={upToMd ? 65 : 30}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <Stack flex={1}>
                  <a
                    href="https://docs.usdx.money/a-synthetic-usd/usdx-basics"
                    target="_blank"
                  >
                    <MeetButton variant="contained">Buy</MeetButton>
                  </a>
                </Stack>
                <Stack flex={1} ml={23}>
                  <a href="https://app.usdx.money/buy" target="_blank">
                    <MeetButtonOut variant="outlined">Learn More</MeetButtonOut>
                  </a>
                </Stack>
              </Stack>
            </TokenBox>
          </Grid>
          <Grid xs={12} md={6}>
            <TokenBox>
              <Stack
                mt={65}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <ImgCon src={SUSDX} />
              </Stack>
              <TokenName mt={36}>sUSDX</TokenName>
              <Title mt={36}>A rebase token with yield</Title>
              <Info mt={36}>
                sUSDX is the staked USDX which provides a multiple-layer savings
                tool including funding rate and crypto ecosystem yield
                accessible worldwide in USD denomination.
              </Info>
              <Stack
                mt={65}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <Stack flex={1}>
                  <a
                    href="https://docs.usdx.money/a-yield-bearing-token/staking-usdx"
                    target="_blank"
                  >
                    <MeetButton variant="contained">Stake</MeetButton>
                  </a>
                </Stack>
                <Stack flex={1} ml={23}>
                  <a href="https://app.usdx.money/stake" target="_blank">
                    <MeetButtonOut variant="outlined">Learn more</MeetButtonOut>
                  </a>
                </Stack>
              </Stack>
            </TokenBox>
          </Grid>
        </Grid>
      </Containter>
    </MeetBox>
  );
};

export default Meet;
