import { styled } from "@mui/material/styles";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { useShow } from "hooks/useShow";
import { Head, SubHead, Desc } from "./Style";
import AboutBg from "assets/images/home/about-bg.png";

const AboutBox = styled(Stack)`
  /* position: relative; */
`;

const ContentBox = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      flexDirection: "column-reverse",
      paddingBottom: "50px",
      alignItems: "center",
    },
  })};
`;

const ImgCon = styled(Stack)`
  position: relative;
`;

const BgImg = styled("img")`
  position: absolute;
  width: 1368px;
  left: -700px;
  top: -285px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "666px",
      left: "-400px",
      top: "0",
    },
  })};
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 180px 25px 200px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const About = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <AboutBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Head>About Stables Labs</Head>
        </Stack>
        <SubHead mt={50}>Implementing the future of finance today</SubHead>
        <ContentBox>
          <ImgCon flex={1}>
            <BgImg src={AboutBg} />
          </ImgCon>
          <Stack ml={30} flex={1}>
            <Desc>
              Stables Labs is the creative and technical powerhouse behind the
              usdx.money innovation. Since the third quarter of 2024, we have
              been at the forefront of innovation, passionately shaping the
              usdx.money protocols and its decentralized ecosystem.
            </Desc>
            <Desc mt={20}>
              Our team, a dynamic blend of visionaries and professionals, merges
              the best of worlds: from traditional finance to cutting-edge DeFi,
              from Web 2.0 to regulatory and political spheres. Together, we are
              more than a team: we are craftsmen of a smarter, more efficient,
              and fundamentally better financial future for everyone.
            </Desc>
            <Desc mt={20}>
              Join us on this journey where each step brings us closer to a
              reinvented financial world, optimized by Stables Labs for you,
              for us, for everyone.
            </Desc>
          </Stack>
        </ContentBox>
      </Containter>
    </AboutBox>
  );
};

export default About;
