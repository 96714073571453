import {
  Box,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Nav from "./Nav";
import LogoBox from "./Logo";
import { useState, useEffect } from "react";
import _ from "lodash";
import { color } from "echarts";

const Wrapper = styled("div")<{ isTop: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 60px;
  background: ${({ isTop }) => (isTop ? "#0E0E0E" : "#242426")};
  animation: ${({ isTop }) =>
    isTop ? " up 0.5s forwards" : "down 0.5s forwards"};
  box-shadow: 0px 2px 10px 0px rgba(81, 45, 45, 0.05);
  /* backdrop-filter: blur(20px); */
  z-index: 99;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      height: "60px",
      top: 0,
      padding: "0px 25px",
    },
  })};
  @keyframes down {
    from {
      background: #0e0e0e;
    }
    to {
      background-color: #242426;
    }
  }
  @keyframes up {
    from {
      background: #242426;
    }
    to {
      background-color: #0e0e0e;
    }
  }
`;
const Flex = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 120px;
  width: 100%;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {},
  })};
`;

const HeadButton = styled(Button)<{ isTop: boolean }>`
  border-radius: 45px;
  height: 45px;
  min-width: 160px;
  border: 1px solid transparent;
  border-radius: 45px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: ${({ isTop }) =>
    isTop
      ? `linear-gradient(to right, #0E0E0E, #0E0E0E),
  linear-gradient(90deg, #1F7A55 0%, #EAE3C5 100%)`
      : `linear-gradient(to right, #242426, #242426),
  linear-gradient(90deg, #1F7A55 0%, #EAE3C5 100%)`};
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      minWidth: "100px",
      height: "30px",
      fontSize: "12px",
      color: "#fff",
      backgroundClip: "none",
      padding: "15px 0px",
      marginRight: "50px",
      position: "absolute",
      right: "10px",
      top: "18px",
    },
  })};
`;

const Header = () => {
  const theme = useTheme();
  const upToLg = useMediaQuery(theme.breakpoints.up("lg"));
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const handleScroll = _.debounce(() => {
      if (document.body.scrollTop <= 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    }, 50);
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (document.body.scrollTop <= 0) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
  }, []);
  return (
    <Wrapper isTop={isTop}>
      <LogoBox isTop={isTop} />
      <Flex>
        <Nav isTop={isTop} />
      </Flex>
      <Stack alignContent="center" justifyContent={"center"} direction="row">
        <a href="https://app.usdx.money/" target="_blank">
          <HeadButton isTop={isTop} variant="outlined">
            Enter App
          </HeadButton>
        </a>
      </Stack>
    </Wrapper>
  );
};

export default Header;
