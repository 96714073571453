import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import { Head, SubHead, Desc } from "./Style";
import LabBg from "assets/images/home/lab-bg.svg";
import BgDot from "assets/images/bg-dot.png";

const LabBox = styled(Stack)`
  /* position: relative; */
  background: url(${LabBg}) no-repeat;
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 15px 25px 15px;
  margin-top: 350px;
  margin-bottom: 350px;
  box-sizing: content-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "50px",
    },
  })};
`;

const ContentBox = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 184px;
  padding: 40px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      flexDirection: "column",
      paddingBottom: "50px",
      alignItems: "center",
    },
  })};
`;

const LabCon = styled(Stack)`
  width: 450px;
`;

const LabSubHead = styled(SubHead)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  })};
`;

const Info = styled(Typography)`
  color: #c6c6c6;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  })};
`;

const LabList = styled(Stack)`
  padding-left: 15px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 14px;
    left: 0px;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border-radius: 50%;
  }
`;

const ImgBg1 = styled("img")`
  position: absolute;
  width: 1600px;
  left: 20%;
  top: 20%;
`;

const Lab = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <LabBox justifyContent="center" alignItems="center" direction="row">
      <ImgBg1 src={BgDot} alt="" />
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Head>Stables Labs Roadmap</Head>
        </Stack>
        <ContentBox>
          <LabCon>
            <LabSubHead>DeFi Stage</LabSubHead>
            <Info mt={upToMd ? 5 : 20}>Primitive collateral in DeFi</Info>
            <Stack justifyContent="center" alignItems="center" direction="row">
              <Box mt={30}>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>sUSDX as collateral in money market</Desc>
                </LabList>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>sUSDX as margin collateral in perpetual DEXs</Desc>
                </LabList>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>sUSDX as base asset for interest rate swaps</Desc>
                </LabList>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>sUSDX as back-end infra for stablecoin issuers</Desc>
                </LabList>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>USDx as money in spot AMM DEXs</Desc>
                </LabList>
              </Box>
            </Stack>
          </LabCon>
          <LabCon mt={upToMd ? 0 : 60}>
            <LabSubHead>CeFi Stage</LabSubHead>
            <Info mt={upToMd ? 5 : 20}>Money in CeFi</Info>
            <Stack justifyContent="center" alignItems="center" direction="row">
              <Box mt={30}>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>USDX as money in CEX spot trading pairs</Desc>
                </LabList>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>sUSDX as collateral in CEX perpetual tradings</Desc>
                </LabList>
              </Box>
            </Stack>
          </LabCon>
        </ContentBox>
        <Stack
          mt={upToMd ? 127 : 60}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <LabCon>
            <LabSubHead>PayFi Stage</LabSubHead>
            <Info mt={upToMd ? 5 : 20}>Payment vehicle with yield</Info>
            <Stack justifyContent="center" alignItems="center" direction="row">
              <Box mt={30}>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>The special uncorrelated dollar yield </Desc>
                </LabList>
                <LabList
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <Desc>Payment services for unbanked people</Desc>
                </LabList>
              </Box>
            </Stack>
          </LabCon>
        </Stack>
      </Containter>
    </LabBox>
  );
};

export default Lab;
