import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import p10T from "assets/images/partners/10T.png";
import BAI from "assets/images/partners/BAI.png";
import ceffu from "assets/images/partners/ceffu.png";
import dragonfly from "assets/images/partners/dragonfly.png";
import gen from "assets/images/partners/gen.png";
import Jeneration from "assets/images/partners/Jeneration.png";
import NGC from "assets/images/partners/NGC.png";
import UOB from "assets/images/partners/UOB.png";
import vebetterdao from "assets/images/partners/vebetterdao.png";
import ZooCap from "assets/images/partners/ZooCap.png";
import RedStone from "assets/images/partners/RedStone.svg";

import Backedby from "assets/images/partners/Backedby.png";
import Custodian from "assets/images/partners/Custodian.png";
import PartnersImg from "assets/images/partners/Partners.png";

const PartnersBox = styled(Stack)`
  width: 100%;
  margin-top: 100px;
`;

const ParOut = styled(Stack)`
  height: 25px;
  width: 100%;
  padding: 25px;
  overflow: hidden;
  position: relative;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgb(0, 0, 0) 12.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const ImgCon = styled("img")`
  width: auto;
  height: 25px;
  margin-right: 50px;
`;

const TextCon = styled(Typography)`
  width: auto;
  height: 25px;
  margin-right: 50px;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  line-height: 25px;
  display: inline-block;
`;

const ParInner = styled(Stack)`
  @keyframes move {
    0% {
      left: 0;
    }
    100% {
      left: -1840px;
    }
  }
  position: absolute;
  top: 25px;
  left: 0;
  height: 25px;
  animation: 20s move infinite linear;
  -webkit-animation: 20s move infinite linear;
  &:hover {
    animation-play-state: paused;
  }
`;

const Partners = () => {
  const ParList = [
    <TextCon>Partners</TextCon>,
    <ImgCon style={{ height: "30px", marginTop: "5px" }} src={vebetterdao} />,
    <ImgCon src={RedStone} />,
    <TextCon>Custodian</TextCon>,
    <ImgCon src={ceffu} />,
    <TextCon style={{ minWidth: "120px" }}>Backed by</TextCon>,
    <ImgCon style={{ height: "30px", marginTop: "5px" }} src={dragonfly} />,
    <ImgCon src={NGC} />,
    <ImgCon src={p10T} />,
    <ImgCon src={BAI} />,
    <ImgCon src={gen} />,
    // <ImgCon src={Jeneration} />,
    <ImgCon src={UOB} />,
    // <ImgCon src={ZooCap} />,
  ];
  return (
    <PartnersBox justifyContent="center" alignItems="center" direction="row">
      <ParOut justifyContent="start" alignItems="center" direction="row">
        <ParInner justifyContent="start" alignItems="center" direction="row">
          {ParList.map((d: any) => (
            <Box>{d}</Box>
          ))}
          {ParList.map((d: any) => (
            <Box>{d}</Box>
          ))}
          {ParList.map((d: any) => (
            <Box>{d}</Box>
          ))}
        </ParInner>
      </ParOut>
    </PartnersBox>
  );
};

export default Partners;
