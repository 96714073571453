import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import Containter from "components/Containter";
import { ReactNode } from "react";
import { Medium, Telegram, Twitter, Discord, Logo } from "components/Svg";
import Grid from "@mui/material/Unstable_Grid2";
import { NavLink } from "react-router-dom";

const Footer1Box = styled(Stack)`
  background: #272727;
`;

const Footer2Box = styled(Stack)`
  background: #191919;
`;

const Footer1Title = styled(Typography)`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Footer1Info = styled(Typography)<{ isComing: boolean; desc?: string }>`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  cursor: pointer;
  &:hover {
    color: ${({ isComing }) =>
      isComing ? "rgba(255, 255, 255, 0.6)" : "#fff"};
  }
  &::after {
    display: ${({ isComing }) => (isComing ? "block" : "none")};
    position: absolute;
    content: "Coming Soon";
    font-size: 8px;
    color: #fff;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 21px;
    padding: 3px 5px;
    top: -5px;
    right: -65px;
  }
`;

const Footer2Text = styled(Typography)`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const GridLogoWhite = styled(Grid)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  })};
`;

const Footer1Desc = styled("a")`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-left: 3px;
`;

const Footer1Item = styled(Stack)`
  .fill-color {
    fill: rgba(255, 255, 255, 0.6);
  }
  &:hover {
    .fill-color {
      fill: #fff;
    }
  }
`;

interface subConItem {
  name: string;
  path: string;
  icon?: ReactNode;
  isComing?: boolean;
  target?: string;
  desc?: string;
}

interface FooterItem {
  name: string;
  subCon: subConItem[];
}

const Footer = () => {
  // const theme = useTheme();
  // const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const goTop = () => {
    document.body.scrollTop = 0;
  };
  const footerData: FooterItem[] = [
    {
      name: "Resource",
      subCon: [
        {
          name: "Docs",
          path: "https://docs.usdx.money/",
          target: "_blank",
        },
        {
          name: "Brand Assets",
          path: "/usdx_assets.zip",
          target: "_blank",
        },
      ],
    },
    {
      name: "Community",
      subCon: [
        {
          name: "Twitter",
          icon: <Twitter />,
          path: "https://twitter.com/usdx_money ",
          target: "_blank",
        },
        {
          name: "Telegram",
          icon: (
            <Box ml={-5} mr={-5}>
              <Telegram />
            </Box>
          ),
          path: "https://t.me/USDX_Community",
          target: "_blank",
        },
        {
          name: "Telegram News",
          icon: (
            <Box ml={-5} mr={-5}>
              <Telegram />
            </Box>
          ),
          path: "https://t.me/usdxmoneyNews",
          target: "_blank",
        },
        {
          name: "Discord",
          icon: <Discord />,
          path: "https://discord.gg/usdxmoney",
        },
        // {
        //   name: "Medium",
        //   icon: (
        //     <Box mt={5}>
        //       <Medium />
        //     </Box>
        //   ),
        //   path: "",
        //   target: "_blank",
        // },
      ],
    },
    {
      name: "Statement",
      subCon: [
        {
          name: "Privacy Policy",
          path: "/pdf/privacy-policy.pdf",
          target: "_blank",
        },
        {
          name: "Terms of Service",
          path: "/pdf/terms-service.pdf",
          target: "_blank",
        },
      ],
    },
    {
      name: "Others",
      subCon: [
        {
          name: "Rewards",
          path: "https://app.usdx.money/airdrop",
          target: "_blank",
        },
        {
          name: "Contact:",
          path: "mailto:desk@usdx.money",
          desc: "desk@usdx.money",
        },
      ],
    },
  ];
  return (
    <Box>
      <Footer1Box>
        <Containter>
          <Stack py={60}>
            <Grid container spacing={10}>
              <GridLogoWhite xs={12} md={2}>
                <Logo />
              </GridLogoWhite>
              {footerData.map((d: FooterItem, index: number) => (
                <>
                  <Grid xs={12} md={2.5} mb={20}>
                    <Box mb={10}>
                      <Footer1Title>{d.name}</Footer1Title>
                    </Box>
                    {d.subCon.map((subItem: subConItem) => (
                      <>
                        {subItem?.isComing ? (
                          <>
                            <Footer1Item
                              justifyContent="start"
                              alignItems="center"
                              direction="row"
                              mt={15}
                            >
                              <Footer1Info
                                isComing={subItem?.isComing || false}
                                desc={subItem?.desc || ""}
                              >
                                {subItem.name}
                              </Footer1Info>
                            </Footer1Item>
                          </>
                        ) : (
                          <NavLink
                            onClick={() => {
                              !subItem.desc && goTop();
                            }}
                            to={subItem.path}
                            target={subItem?.target || ""}
                          >
                            <Footer1Item
                              justifyContent="start"
                              alignItems="center"
                              direction="row"
                              mt={15}
                            >
                              {subItem?.icon && (
                                <Box mr={10}>{subItem?.icon}</Box>
                              )}
                              <Box>
                                <Footer1Info
                                  isComing={subItem?.isComing || false}
                                >
                                  {subItem.name}
                                  {subItem.desc && (
                                    <Footer1Desc
                                      target="_blank"
                                      href={subItem.path}
                                    >
                                      {subItem.desc}
                                    </Footer1Desc>
                                  )}
                                </Footer1Info>
                              </Box>
                            </Footer1Item>
                          </NavLink>
                        )}
                      </>
                    ))}
                  </Grid>
                </>
              ))}
            </Grid>
          </Stack>
        </Containter>
      </Footer1Box>
      <Footer2Box
        justifyContent="center"
        alignItems="center"
        direction="row"
        py={15}
      >
        <Footer2Text>© 2024 usdx.money all rights reserved.</Footer2Text>
      </Footer2Box>
    </Box>
  );
};

export default Footer;
