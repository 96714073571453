import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import { match } from "assert";

const BanBox = styled(Stack)`
  position: relative;
  width: 100%;
  height: calc(100vh - 70px); /* 让 Banner 充满首屏 */
  overflow: hidden;
  margin-top: 70px;
  background-image: ${`url("/images/banner-bg.svg?key=${Math.random() * 10}")`};
  background-size: auto 98%;
  background-repeat: no-repeat;
  background-position: center;
`;

const ConBox = styled(Stack)`
  width: 1000px;
`;

const Head = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 86px;
  font-weight: 700;
  line-height: 95px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "46px",
      lineHeight: "50px",
    },
  })};
`;

const SubHead = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      fontSize: "30px",
    },
  })};
`;

const SubHead2 = styled(Typography)`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
      fontSize: "20px",
    },
  })};
`;

const Desc = styled(Typography)`
  color: #c6c6c6;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "60px",
      fontSize: "16px",
      padding: "5px 30px",
      lineHeight: "22px",
    },
  })};
`;

const BannerButton = styled(Button)`
  border-radius: 68px;
  height: 68px;
  min-width: 258px;
  border: 1px solid transparent;
  border-radius: 45px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #0e0e0e, #0e0e0e),
    linear-gradient(90deg, #ffffff 0%, #353535 50%, #6f6f6f 100%);
  box-sizing: border-box;
  margin-top: 60px;
`;

const Banner = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <BanBox
      ref={ref}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <ConBox>
        <Head>{`Next Gen`}</Head>
        <Head>{`Stablecoin Infra`}</Head>
        <SubHead mt={5}>{`for the future of finance`}</SubHead>
        <SubHead2 mt={20}>{`Seeking Stability in Volatility`}</SubHead2>
        <Desc mt={40}>
          usdx.money is a secure and decentralized bankless synthetic stablecoin
          issuer with the mission to better serve people with next gen
          stablecoin infra connecting DeFi, CeFi, and TradFi.
        </Desc>
        <Stack alignContent="center" justifyContent={"center"} direction="row">
          <a href="https://app.usdx.money/" target="_blank">
            <BannerButton variant="outlined">Enter App</BannerButton>
          </a>
        </Stack>
      </ConBox>
    </BanBox>
  );
};

export default Banner;
