import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import { Head, SubHead, Desc } from "./Style";
import Grid from "@mui/material/Unstable_Grid2";
import blog1 from "assets/images/blog-1.png";
import blog2 from "assets/images/blog-2.png";
import blog3 from "assets/images/blog-3.png";
import iconRight from "assets/images/icon-right.svg";

const BlogBox = styled(Stack)`
  /* position: relative; */
  z-index: 2;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 15px 25px 275px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const ImgBox = styled(Stack)`
  height: 212px;
  background: #363636;
`;

const ImgCon = styled("img")`
  height: 212px;
  width: 100%;
`;

const BlogItem = styled(Stack)`
  height: 517px;
  border-radius: 36px;
  border-radius: 45px;
  border: 1px solid #575757;
  background: #0e0e0e;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:hover {
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #0e0e0e, #0e0e0e),
      linear-gradient(90deg, #ffffff 0%, #353535 50%, #6f6f6f 100%);
  }
`;

const Title = styled(Typography)`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const BlogCon = styled(Stack)``;

const BottomBox = styled(Stack)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const BottomCon = styled(Stack)`
  padding: 30px;
`;
const DateText = styled(Typography)`
  color: #bebebe;
  font-family: Arboria-Book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ImgRight = styled("img")`
  height: 8px;
  width: 16px;
`;

const Blog = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <BlogBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Head>Blog</Head>
        </Stack>
        <Grid mt={90} container spacing={30}>
          <Grid xs={12} md={4}>
            <a
              href="https://decrypt.co/294298/usdx-money-secures-45m-new-financing-at-275m-valuation"
              target="_blank"
            >
              <BlogItem>
                <ImgBox>
                  <ImgCon src={blog1} />
                </ImgBox>
                <BlogCon p={30}>
                  <Title>
                    usdx.money Secures $45m New Financing at $275m Valuation.
                  </Title>
                  <BottomBox>
                    <BottomCon
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <DateText>December 2, 2024</DateText>
                      <ImgRight src={iconRight}></ImgRight>
                    </BottomCon>
                  </BottomBox>
                </BlogCon>
              </BlogItem>
            </a>
          </Grid>
          <Grid xs={12} md={4}>
            <a
              href="https://medium.com/@USDX.money/first-campaign-season-is-live-explore-the-full-landscape-of-the-usdx-money-x-points-system-30befa865722"
              target="_blank"
            >
              <BlogItem>
                <ImgBox>
                  <ImgCon src={blog2} />
                </ImgBox>
                <BlogCon p={30}>
                  <Title>
                    First Campaign Season is live! Explore the full landscape of
                    the usdx.money X-Points system.
                  </Title>
                  <BottomBox>
                    <BottomCon
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <DateText>December 3, 2024</DateText>
                      <ImgRight src={iconRight}></ImgRight>
                    </BottomCon>
                  </BottomBox>
                </BlogCon>
              </BlogItem>
            </a>
          </Grid>
          <Grid xs={12} md={4}>
            <a
              href="https://medium.com/@USDX.money/first-campaign-season-participation-guide-earn-more-x-points-to-unlock-exciting-airdrop-rewards-1dc00f4312f8"
              target="_blank"
            >
              <BlogItem>
                <ImgBox>
                  <ImgCon src={blog3} />
                </ImgBox>
                <BlogCon p={30}>
                  <Title>First Campaign Season GUIDE! </Title>
                  <BottomBox>
                    <BottomCon
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <DateText>December 3, 2024</DateText>
                      <ImgRight src={iconRight}></ImgRight>
                    </BottomCon>
                  </BottomBox>
                </BlogCon>
              </BlogItem>
            </a>
          </Grid>
        </Grid>
      </Containter>
    </BlogBox>
  );
};

export default Blog;
