import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Logo: React.FC<IconProps> = ({ width = "30px", height = "30px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
      >
        <g clip-path="url(#clip0_1892_2113)">
          <path
            d="M30.72 0.00266735C27.3979 0.00266735 24.1651 0.0066618 20.9309 4.37945e-06C20.128 -0.0013271 19.5062 0.30092 19.0242 0.948021C17.5969 2.86669 16.1549 4.77337 14.7182 6.68538C14.0485 7.57881 13.3788 8.4709 12.6997 9.37631C12.6278 9.28577 12.5719 9.21787 12.5186 9.1473C11.1898 7.37642 9.85032 5.61354 8.53748 3.83202C8.09942 3.23817 7.56816 2.95457 6.82119 2.96256C4.68283 2.98785 2.54447 2.97188 0.407434 2.97188H0C0.10785 3.11834 0.175756 3.21421 0.246325 3.30874C3.01981 6.98497 5.79195 10.6625 8.57343 14.3321C8.73454 14.5465 8.74252 14.6756 8.57742 14.8953C5.80527 18.5729 3.04244 22.2584 0.27828 25.9413C0.2157 26.0265 0.157115 26.1144 0.0625798 26.2489H0.470014C2.60705 26.2489 4.74541 26.2396 6.88244 26.2555C7.5615 26.2595 8.06879 26.0092 8.4709 25.4673C9.81171 23.6631 11.1645 21.8696 12.5133 20.0721C12.5692 19.9989 12.6331 19.931 12.7037 19.8471C12.7769 19.9403 12.8328 20.0082 12.8861 20.0774C14.9246 22.7963 16.9684 25.5126 18.9976 28.2368C19.4943 28.9025 20.1254 29.2221 20.9549 29.2194C24.0013 29.2088 27.0478 29.2154 30.0942 29.2154H30.6534C26.9745 24.3142 23.3383 19.4703 19.6953 14.6184C23.3675 9.75179 27.0265 4.9012 30.72 0.00266735ZM25.5685 26.5604C25.6004 26.6031 25.6337 26.6443 25.7123 26.7442C24.1371 26.7442 22.6326 26.7482 21.128 26.7335C21.0175 26.7322 20.8817 26.607 20.8031 26.5045C19.0908 24.233 17.3839 21.9575 15.6769 19.682C14.4626 18.0616 13.2483 16.4398 12.034 14.8181C11.982 14.7475 11.9328 14.6756 11.8755 14.5931C12.7902 13.3748 13.6983 12.1618 14.6077 10.9501C16.6675 8.20727 18.7313 5.46575 20.7858 2.71756C20.9283 2.52716 21.0721 2.45925 21.3064 2.46059C22.7657 2.47124 24.225 2.46591 25.7376 2.46591C25.6617 2.58175 25.6151 2.66297 25.5592 2.73753C22.6472 6.59884 19.7366 10.4628 16.8166 14.3188C16.6502 14.5385 16.6382 14.6756 16.8113 14.906C19.7366 18.7859 22.6512 22.6738 25.5685 26.5604Z"
            fill="white"
            className="fill-color"
          />
        </g>
        <defs>
          <clipPath id="clip0_1892_2113">
            <rect className="fill-color" width="30.72" height="29.2194" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Logo;
