import { styled } from "@mui/material/styles";
import { Button, Stack, Typography } from "@mui/material";

export const XHead = styled(Typography)`
  background: var(
    --Linear,
    linear-gradient(93deg, #1f7a55 15.72%, #c0c0c4 71.42%, #eae3c5 89.98%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 106px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  span {
    font-size: 52px;
    ${({ theme }) => ({
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    })};
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "60px",
      textAlign: "center",
    },
  })};
`;

export const XDesc = styled(Typography)`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 50px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      textAlign: "center",
    },
  })};
`;

export const XButton = styled(Button)`
  border-radius: 58px;
  height: 58px;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #fff;
  margin-top: 45px;
  min-width: 207px;
`;

export const Head = styled(Typography)`
  text-align: center;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --Linear,
    linear-gradient(93deg, #1f7a55 15.72%, #c0c0c4 71.42%, #eae3c5 89.98%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
  })};
`;

export const SubHead = styled(Typography)`
  color: #fff;
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  })};
`;

export const Desc = styled(Typography)`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })};
`;
