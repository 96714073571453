// import styled from "@emotion/styled";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { Logo, LogoUSDX } from "components/Svg";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const LogoWapper = styled(Stack)<{ isTop: boolean }>`
  svg {
    .fill-color {
      fill: ${({ isTop }) => (isTop ? "#fff" : "#fff")};
    }
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      svg: {
        // marginTop: "10px",
      },
    },
  })};
`;

const LogoBox = ({ isTop }: { isTop: boolean }) => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <LogoWapper
      isTop={isTop}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <NavLink to={"/"}>
        <Stack
          alignContent="center"
          justifyContent={"flex-start"}
          direction="row"
        >
          <Logo
            width={downToMd ? "20px" : "30px"}
            height={downToMd ? "20px" : "30px"}
          ></Logo>
          <Stack alignItems="center" direction="row" ml={10}>
            <LogoUSDX width={downToMd ? "115px" : "178px"} height={downToMd ? "15px" : "23px"}></LogoUSDX>
          </Stack>
        </Stack>
      </NavLink>
    </LogoWapper>
  );
};

export default LogoBox;
