import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import { XHead, XDesc } from "./Style";
import Transitions from "components/Transitions";

const XForBox = styled(Stack)`
  position: relative;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 130px 25px 100px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const ContentBox = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      flexDirection: "column-reverse",
      paddingBottom: "50px",
      alignItems: "center",
    },
  })};
`;

const ContentBox2 = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      flexDirection: "column",
      paddingBottom: "50px",
      alignItems: "center",
    },
  })};
`;

const LeftBox = styled(Stack)`
  margin-right: 30px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 530px;
  height: auto;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  })};
`;

const XFor = () => {
  return (
    <XForBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <XFor1 />
        <XFor2 />
        <XFor3 />
      </Containter>
    </XForBox>
  );
};

const XFor1 = () => {
  const { ref, isShow } = useShow();
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ContentBox2 ref={ref}>
      <LeftBox direction="row" alignItems={"center"} flex={1}>
        <Box>
          <Transitions delay={200} isShow={isShow}>
            <XHead>
              X <span>for more flexiblity</span>
            </XHead>
          </Transitions>
          <Transitions delay={400} isShow={isShow}>
            <XDesc>
              With a single click, users can unlock yield, accessing swift
              returns. This smooth action lets you effortlessly transition back
              to other stablecoins. The brief waiting period keeps your
              financial journey uninterrupted, flowing seamlessly like a calm
              river, free from time constraints.
            </XDesc>
          </Transitions>

          {/* <a href="https://app.usdx.money/" target="_blank">
                <XButton variant="outlined">
                  {`Immediate access`.toLocaleUpperCase()}
                </XButton>
              </a> */}
        </Box>
      </LeftBox>
      <Stack
        justifyContent="center"
        alignItems={"flex-start"}
        direction="row"
        ml={upToMd ? 30 : 0}
      >
        <Transitions delay={400} isShow={isShow}>
          <ImgCon
            src={`/images/x-flexiblity.svg?key=${Math.random() * 10}`}
            alt="Workflow"
          />
        </Transitions>
      </Stack>
    </ContentBox2>
  );
};

const XFor2 = () => {
  const { ref, isShow } = useShow();
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ContentBox ref={ref}>
      <Stack
        justifyContent="center"
        alignItems={"flex-start"}
        direction="row"
        mr={upToMd ? 80 : 0}
      >
        <Transitions delay={400} isShow={isShow}>
          <ImgCon
            src={`/images/x-connected.svg?key=${Math.random() * 10}`}
            alt="Workflow"
          />
        </Transitions>
      </Stack>
      <Stack
        justifyContent={{ xs: "center", md: "flex-end" }}
        alignItems={"center"}
        direction="row"
      >
        <Box>
          <Transitions delay={200} isShow={isShow}>
            <Box>
              <XHead>
                X <span>for more</span>
              </XHead>
              <XHead>
                <span> connected finance</span>
              </XHead>
            </Box>
          </Transitions>
          <Transitions delay={200} isShow={isShow}>
            <XDesc>
              We aim to revolutionize finance by integrating DeFi, CeFi, and
              TradFi on the usdx.money platform, enhancing connectivity and
              efficiency. This blend combines DeFi's innovation, CeFi's
              compliance, and TradFi's stability, creating a unified,
              accessible, and robust financial service standard.
            </XDesc>
          </Transitions>

          {/* <a href="https://app.usdx.money/" target="_blank">
          <XButton variant="outlined">
            {`X the finance`.toLocaleUpperCase()}
          </XButton>
        </a> */}
        </Box>
      </Stack>
    </ContentBox>
  );
};

const XFor3 = () => {
  const { ref, isShow } = useShow();
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ContentBox2 ref={ref}>
      <LeftBox direction="row" alignItems={"center"} flex={1}>
        <Box>
          <Transitions delay={200} isShow={isShow}>
            <XHead>
              X <span>for more ownership</span>
            </XHead>
          </Transitions>
          <Transitions delay={400} isShow={isShow}>
            <XDesc>
              usdx.money protocol redistributes power and ownership to TVL
              providers, allowing them to own the infrastructure and revenues,
              similar to a model where Tether’s TVL providers would benefit
              directly from the platform’s growth and earnings.
            </XDesc>
          </Transitions>

          {/* <a href="https://app.usdx.money/" target="_blank">
                <XButton variant="outlined">
                  {`X token`.toLocaleUpperCase()}
                </XButton>
              </a> */}
        </Box>
      </LeftBox>
      <Stack
        justifyContent="center"
        alignItems={"flex-start"}
        direction="row"
        ml={upToMd ? 30 : 0}
      >
        <Transitions delay={400} isShow={isShow}>
          <ImgCon
            src={`/images/x-ownership.svg?key=${Math.random() * 10}`}
            alt="Workflow"
          />
        </Transitions>
      </Stack>
    </ContentBox2>
  );
};

export default XFor;
