export const list = {
  Date: [
    "6/6/2020",
    "6/7/2020",
    "6/8/2020",
    "6/9/2020",
    "6/10/2020",
    "6/11/2020",
    "6/12/2020",
    "6/13/2020",
    "6/14/2020",
    "6/15/2020",
    "6/16/2020",
    "6/17/2020",
    "6/18/2020",
    "6/19/2020",
    "6/20/2020",
    "6/21/2020",
    "6/22/2020",
    "6/23/2020",
    "6/24/2020",
    "6/25/2020",
    "6/26/2020",
    "6/27/2020",
    "6/28/2020",
    "6/29/2020",
    "6/30/2020",
    "7/1/2020",
    "7/2/2020",
    "7/3/2020",
    "7/4/2020",
    "7/5/2020",
    "7/6/2020",
    "7/7/2020",
    "7/8/2020",
    "7/9/2020",
    "7/10/2020",
    "7/11/2020",
    "7/12/2020",
    "7/13/2020",
    "7/14/2020",
    "7/15/2020",
    "7/16/2020",
    "7/17/2020",
    "7/18/2020",
    "7/19/2020",
    "7/20/2020",
    "7/21/2020",
    "7/22/2020",
    "7/23/2020",
    "7/24/2020",
    "7/25/2020",
    "7/26/2020",
    "7/27/2020",
    "7/28/2020",
    "7/29/2020",
    "7/30/2020",
    "7/31/2020",
    "8/1/2020",
    "8/2/2020",
    "8/3/2020",
    "8/4/2020",
    "8/5/2020",
    "8/6/2020",
    "8/7/2020",
    "8/8/2020",
    "8/9/2020",
    "8/10/2020",
    "8/11/2020",
    "8/12/2020",
    "8/13/2020",
    "8/14/2020",
    "8/15/2020",
    "8/16/2020",
    "8/17/2020",
    "8/18/2020",
    "8/19/2020",
    "8/20/2020",
    "8/21/2020",
    "8/22/2020",
    "8/23/2020",
    "8/24/2020",
    "8/25/2020",
    "8/26/2020",
    "8/27/2020",
    "8/28/2020",
    "8/29/2020",
    "8/30/2020",
    "8/31/2020",
    "9/1/2020",
    "9/2/2020",
    "9/3/2020",
    "9/4/2020",
    "9/5/2020",
    "9/6/2020",
    "9/7/2020",
    "9/8/2020",
    "9/9/2020",
    "9/10/2020",
    "9/11/2020",
    "9/12/2020",
    "9/13/2020",
    "9/14/2020",
    "9/15/2020",
    "9/16/2020",
    "9/17/2020",
    "9/18/2020",
    "9/19/2020",
    "9/20/2020",
    "9/21/2020",
    "9/22/2020",
    "9/23/2020",
    "9/24/2020",
    "9/25/2020",
    "9/26/2020",
    "9/27/2020",
    "9/28/2020",
    "9/29/2020",
    "9/30/2020",
    "10/1/2020",
    "10/2/2020",
    "10/3/2020",
    "10/4/2020",
    "10/5/2020",
    "10/6/2020",
    "10/7/2020",
    "10/8/2020",
    "10/9/2020",
    "10/10/2020",
    "10/11/2020",
    "10/12/2020",
    "10/13/2020",
    "10/14/2020",
    "10/15/2020",
    "10/16/2020",
    "10/17/2020",
    "10/18/2020",
    "10/19/2020",
    "10/20/2020",
    "10/21/2020",
    "10/22/2020",
    "10/23/2020",
    "10/24/2020",
    "10/25/2020",
    "10/26/2020",
    "10/27/2020",
    "10/28/2020",
    "10/29/2020",
    "10/30/2020",
    "10/31/2020",
    "11/1/2020",
    "11/2/2020",
    "11/3/2020",
    "11/4/2020",
    "11/5/2020",
    "11/6/2020",
    "11/7/2020",
    "11/8/2020",
    "11/9/2020",
    "11/10/2020",
    "11/11/2020",
    "11/12/2020",
    "11/13/2020",
    "11/14/2020",
    "11/15/2020",
    "11/16/2020",
    "11/17/2020",
    "11/18/2020",
    "11/19/2020",
    "11/20/2020",
    "11/21/2020",
    "11/22/2020",
    "11/23/2020",
    "11/24/2020",
    "11/25/2020",
    "11/26/2020",
    "11/27/2020",
    "11/28/2020",
    "11/29/2020",
    "11/30/2020",
    "12/1/2020",
    "12/2/2020",
    "12/3/2020",
    "12/4/2020",
    "12/5/2020",
    "12/6/2020",
    "12/7/2020",
    "12/8/2020",
    "12/9/2020",
    "12/10/2020",
    "12/11/2020",
    "12/12/2020",
    "12/13/2020",
    "12/14/2020",
    "12/15/2020",
    "12/16/2020",
    "12/17/2020",
    "12/18/2020",
    "12/19/2020",
    "12/20/2020",
    "12/21/2020",
    "12/22/2020",
    "12/23/2020",
    "12/24/2020",
    "12/25/2020",
    "12/26/2020",
    "12/27/2020",
    "12/28/2020",
    "12/29/2020",
    "12/30/2020",
    "12/31/2020",
    "1/1/2021",
    "1/2/2021",
    "1/3/2021",
    "1/4/2021",
    "1/5/2021",
    "1/6/2021",
    "1/7/2021",
    "1/8/2021",
    "1/9/2021",
    "1/10/2021",
    "1/11/2021",
    "1/12/2021",
    "1/13/2021",
    "1/14/2021",
    "1/15/2021",
    "1/16/2021",
    "1/17/2021",
    "1/18/2021",
    "1/19/2021",
    "1/20/2021",
    "1/21/2021",
    "1/22/2021",
    "1/23/2021",
    "1/24/2021",
    "1/25/2021",
    "1/26/2021",
    "1/27/2021",
    "1/28/2021",
    "1/29/2021",
    "1/30/2021",
    "1/31/2021",
    "2/1/2021",
    "2/2/2021",
    "2/3/2021",
    "2/4/2021",
    "2/5/2021",
    "2/6/2021",
    "2/7/2021",
    "2/8/2021",
    "2/9/2021",
    "2/10/2021",
    "2/11/2021",
    "2/12/2021",
    "2/13/2021",
    "2/14/2021",
    "2/15/2021",
    "2/16/2021",
    "2/17/2021",
    "2/18/2021",
    "2/19/2021",
    "2/20/2021",
    "2/21/2021",
    "2/22/2021",
    "2/23/2021",
    "2/24/2021",
    "2/25/2021",
    "2/26/2021",
    "2/27/2021",
    "2/28/2021",
    "3/1/2021",
    "3/2/2021",
    "3/3/2021",
    "3/4/2021",
    "3/5/2021",
    "3/6/2021",
    "3/7/2021",
    "3/8/2021",
    "3/9/2021",
    "3/10/2021",
    "3/11/2021",
    "3/12/2021",
    "3/13/2021",
    "3/14/2021",
    "3/15/2021",
    "3/16/2021",
    "3/17/2021",
    "3/18/2021",
    "3/19/2021",
    "3/20/2021",
    "3/21/2021",
    "3/22/2021",
    "3/23/2021",
    "3/24/2021",
    "3/25/2021",
    "3/26/2021",
    "3/27/2021",
    "3/28/2021",
    "3/29/2021",
    "3/30/2021",
    "3/31/2021",
    "4/1/2021",
    "4/2/2021",
    "4/3/2021",
    "4/4/2021",
    "4/5/2021",
    "4/6/2021",
    "4/7/2021",
    "4/8/2021",
    "4/9/2021",
    "4/10/2021",
    "4/11/2021",
    "4/12/2021",
    "4/13/2021",
    "4/14/2021",
    "4/15/2021",
    "4/16/2021",
    "4/17/2021",
    "4/18/2021",
    "4/19/2021",
    "4/20/2021",
    "4/21/2021",
    "4/22/2021",
    "4/23/2021",
    "4/24/2021",
    "4/25/2021",
    "4/26/2021",
    "4/27/2021",
    "4/28/2021",
    "4/29/2021",
    "4/30/2021",
    "5/1/2021",
    "5/2/2021",
    "5/3/2021",
    "5/4/2021",
    "5/5/2021",
    "5/6/2021",
    "5/7/2021",
    "5/8/2021",
    "5/9/2021",
    "5/10/2021",
    "5/11/2021",
    "5/12/2021",
    "5/13/2021",
    "5/14/2021",
    "5/15/2021",
    "5/16/2021",
    "5/17/2021",
    "5/18/2021",
    "5/19/2021",
    "5/20/2021",
    "5/21/2021",
    "5/22/2021",
    "5/23/2021",
    "5/24/2021",
    "5/25/2021",
    "5/26/2021",
    "5/27/2021",
    "5/28/2021",
    "5/29/2021",
    "5/30/2021",
    "5/31/2021",
    "6/1/2021",
    "6/2/2021",
    "6/3/2021",
    "6/4/2021",
    "6/5/2021",
    "6/6/2021",
    "6/7/2021",
    "6/8/2021",
    "6/9/2021",
    "6/10/2021",
    "6/11/2021",
    "6/12/2021",
    "6/13/2021",
    "6/14/2021",
    "6/15/2021",
    "6/16/2021",
    "6/17/2021",
    "6/18/2021",
    "6/19/2021",
    "6/20/2021",
    "6/21/2021",
    "6/22/2021",
    "6/23/2021",
    "6/24/2021",
    "6/25/2021",
    "6/26/2021",
    "6/27/2021",
    "6/28/2021",
    "6/29/2021",
    "6/30/2021",
    "7/1/2021",
    "7/2/2021",
    "7/3/2021",
    "7/4/2021",
    "7/5/2021",
    "7/6/2021",
    "7/7/2021",
    "7/8/2021",
    "7/9/2021",
    "7/10/2021",
    "7/11/2021",
    "7/12/2021",
    "7/13/2021",
    "7/14/2021",
    "7/15/2021",
    "7/16/2021",
    "7/17/2021",
    "7/18/2021",
    "7/19/2021",
    "7/20/2021",
    "7/21/2021",
    "7/22/2021",
    "7/23/2021",
    "7/24/2021",
    "7/25/2021",
    "7/26/2021",
    "7/27/2021",
    "7/28/2021",
    "7/29/2021",
    "7/30/2021",
    "7/31/2021",
    "8/1/2021",
    "8/2/2021",
    "8/3/2021",
    "8/4/2021",
    "8/5/2021",
    "8/6/2021",
    "8/7/2021",
    "8/8/2021",
    "8/9/2021",
    "8/10/2021",
    "8/11/2021",
    "8/12/2021",
    "8/13/2021",
    "8/14/2021",
    "8/15/2021",
    "8/16/2021",
    "8/17/2021",
    "8/18/2021",
    "8/19/2021",
    "8/20/2021",
    "8/21/2021",
    "8/22/2021",
    "8/23/2021",
    "8/24/2021",
    "8/25/2021",
    "8/26/2021",
    "8/27/2021",
    "8/28/2021",
    "8/29/2021",
    "8/30/2021",
    "8/31/2021",
    "9/1/2021",
    "9/2/2021",
    "9/3/2021",
    "9/4/2021",
    "9/5/2021",
    "9/6/2021",
    "9/7/2021",
    "9/8/2021",
    "9/9/2021",
    "9/10/2021",
    "9/11/2021",
    "9/12/2021",
    "9/13/2021",
    "9/14/2021",
    "9/15/2021",
    "9/16/2021",
    "9/17/2021",
    "9/18/2021",
    "9/19/2021",
    "9/20/2021",
    "9/21/2021",
    "9/22/2021",
    "9/23/2021",
    "9/24/2021",
    "9/25/2021",
    "9/26/2021",
    "9/27/2021",
    "9/28/2021",
    "9/29/2021",
    "9/30/2021",
    "10/1/2021",
    "10/2/2021",
    "10/3/2021",
    "10/4/2021",
    "10/5/2021",
    "10/6/2021",
    "10/7/2021",
    "10/8/2021",
    "10/9/2021",
    "10/10/2021",
    "10/11/2021",
    "10/12/2021",
    "10/13/2021",
    "10/14/2021",
    "10/15/2021",
    "10/16/2021",
    "10/17/2021",
    "10/18/2021",
    "10/19/2021",
    "10/20/2021",
    "10/21/2021",
    "10/22/2021",
    "10/23/2021",
    "10/24/2021",
    "10/25/2021",
    "10/26/2021",
    "10/27/2021",
    "10/28/2021",
    "10/29/2021",
    "10/30/2021",
    "10/31/2021",
    "11/1/2021",
    "11/2/2021",
    "11/3/2021",
    "11/4/2021",
    "11/5/2021",
    "11/6/2021",
    "11/7/2021",
    "11/8/2021",
    "11/9/2021",
    "11/10/2021",
    "11/11/2021",
    "11/12/2021",
    "11/13/2021",
    "11/14/2021",
    "11/15/2021",
    "11/16/2021",
    "11/17/2021",
    "11/18/2021",
    "11/19/2021",
    "11/20/2021",
    "11/21/2021",
    "11/22/2021",
    "11/23/2021",
    "11/24/2021",
    "11/25/2021",
    "11/26/2021",
    "11/27/2021",
    "11/28/2021",
    "11/29/2021",
    "11/30/2021",
    "12/1/2021",
    "12/2/2021",
    "12/3/2021",
    "12/4/2021",
    "12/5/2021",
    "12/6/2021",
    "12/7/2021",
    "12/8/2021",
    "12/9/2021",
    "12/10/2021",
    "12/11/2021",
    "12/12/2021",
    "12/13/2021",
    "12/14/2021",
    "12/15/2021",
    "12/16/2021",
    "12/17/2021",
    "12/18/2021",
    "12/19/2021",
    "12/20/2021",
    "12/21/2021",
    "12/22/2021",
    "12/23/2021",
    "12/24/2021",
    "12/25/2021",
    "12/26/2021",
    "12/27/2021",
    "12/28/2021",
    "12/29/2021",
    "12/30/2021",
    "12/31/2021",
    "1/1/2022",
    "1/2/2022",
    "1/3/2022",
    "1/4/2022",
    "1/5/2022",
    "1/6/2022",
    "1/7/2022",
    "1/8/2022",
    "1/9/2022",
    "1/10/2022",
    "1/11/2022",
    "1/12/2022",
    "1/13/2022",
    "1/14/2022",
    "1/15/2022",
    "1/16/2022",
    "1/17/2022",
    "1/18/2022",
    "1/19/2022",
    "1/20/2022",
    "1/21/2022",
    "1/22/2022",
    "1/23/2022",
    "1/24/2022",
    "1/25/2022",
    "1/26/2022",
    "1/27/2022",
    "1/28/2022",
    "1/29/2022",
    "1/30/2022",
    "1/31/2022",
    "2/1/2022",
    "2/2/2022",
    "2/3/2022",
    "2/4/2022",
    "2/5/2022",
    "2/6/2022",
    "2/7/2022",
    "2/8/2022",
    "2/9/2022",
    "2/10/2022",
    "2/11/2022",
    "2/12/2022",
    "2/13/2022",
    "2/14/2022",
    "2/15/2022",
    "2/16/2022",
    "2/17/2022",
    "2/18/2022",
    "2/19/2022",
    "2/20/2022",
    "2/21/2022",
    "2/22/2022",
    "2/23/2022",
    "2/24/2022",
    "2/25/2022",
    "2/26/2022",
    "2/27/2022",
    "2/28/2022",
    "3/1/2022",
    "3/2/2022",
    "3/3/2022",
    "3/4/2022",
    "3/5/2022",
    "3/6/2022",
    "3/7/2022",
    "3/8/2022",
    "3/9/2022",
    "3/10/2022",
    "3/11/2022",
    "3/12/2022",
    "3/13/2022",
    "3/14/2022",
    "3/15/2022",
    "3/16/2022",
    "3/17/2022",
    "3/18/2022",
    "3/19/2022",
    "3/20/2022",
    "3/21/2022",
    "3/22/2022",
    "3/23/2022",
    "3/24/2022",
    "3/25/2022",
    "3/26/2022",
    "3/27/2022",
    "3/28/2022",
    "3/29/2022",
    "3/30/2022",
    "3/31/2022",
    "4/1/2022",
    "4/2/2022",
    "4/3/2022",
    "4/4/2022",
    "4/5/2022",
    "4/6/2022",
    "4/7/2022",
    "4/8/2022",
    "4/9/2022",
    "4/10/2022",
    "4/11/2022",
    "4/12/2022",
    "4/13/2022",
    "4/14/2022",
    "4/15/2022",
    "4/16/2022",
    "4/17/2022",
    "4/18/2022",
    "4/19/2022",
    "4/20/2022",
    "4/21/2022",
    "4/22/2022",
    "4/23/2022",
    "4/24/2022",
    "4/25/2022",
    "4/26/2022",
    "4/27/2022",
    "4/28/2022",
    "4/29/2022",
    "4/30/2022",
    "5/1/2022",
    "5/2/2022",
    "5/3/2022",
    "5/4/2022",
    "5/5/2022",
    "5/6/2022",
    "5/7/2022",
    "5/8/2022",
    "5/9/2022",
    "5/10/2022",
    "5/11/2022",
    "5/12/2022",
    "5/13/2022",
    "5/14/2022",
    "5/15/2022",
    "5/16/2022",
    "5/17/2022",
    "5/18/2022",
    "5/19/2022",
    "5/20/2022",
    "5/21/2022",
    "5/22/2022",
    "5/23/2022",
    "5/24/2022",
    "5/25/2022",
    "5/26/2022",
    "5/27/2022",
    "5/28/2022",
    "5/29/2022",
    "5/30/2022",
    "5/31/2022",
    "6/1/2022",
    "6/2/2022",
    "6/3/2022",
    "6/4/2022",
    "6/5/2022",
    "6/6/2022",
    "6/7/2022",
    "6/8/2022",
    "6/9/2022",
    "6/10/2022",
    "6/11/2022",
    "6/12/2022",
    "6/13/2022",
    "6/14/2022",
    "6/15/2022",
    "6/16/2022",
    "6/17/2022",
    "6/18/2022",
    "6/19/2022",
    "6/20/2022",
    "6/21/2022",
    "6/22/2022",
    "6/23/2022",
    "6/24/2022",
    "6/25/2022",
    "6/26/2022",
    "6/27/2022",
    "6/28/2022",
    "6/29/2022",
    "6/30/2022",
    "7/1/2022",
    "7/2/2022",
    "7/3/2022",
    "7/4/2022",
    "7/5/2022",
    "7/6/2022",
    "7/7/2022",
    "7/8/2022",
    "7/9/2022",
    "7/10/2022",
    "7/11/2022",
    "7/12/2022",
    "7/13/2022",
    "7/14/2022",
    "7/15/2022",
    "7/16/2022",
    "7/17/2022",
    "7/18/2022",
    "7/19/2022",
    "7/20/2022",
    "7/21/2022",
    "7/22/2022",
    "7/23/2022",
    "7/24/2022",
    "7/25/2022",
    "7/26/2022",
    "7/27/2022",
    "7/28/2022",
    "7/29/2022",
    "7/30/2022",
    "7/31/2022",
    "8/1/2022",
    "8/2/2022",
    "8/3/2022",
    "8/4/2022",
    "8/5/2022",
    "8/6/2022",
    "8/7/2022",
    "8/8/2022",
    "8/9/2022",
    "8/10/2022",
    "8/11/2022",
    "8/12/2022",
    "8/13/2022",
    "8/14/2022",
    "8/15/2022",
    "8/16/2022",
    "8/17/2022",
    "8/18/2022",
    "8/19/2022",
    "8/20/2022",
    "8/21/2022",
    "8/22/2022",
    "8/23/2022",
    "8/24/2022",
    "8/25/2022",
    "8/26/2022",
    "8/27/2022",
    "8/28/2022",
    "8/29/2022",
    "8/30/2022",
    "8/31/2022",
    "9/1/2022",
    "9/2/2022",
    "9/3/2022",
    "9/4/2022",
    "9/5/2022",
    "9/6/2022",
    "9/7/2022",
    "9/8/2022",
    "9/9/2022",
    "9/10/2022",
    "9/11/2022",
    "9/12/2022",
    "9/13/2022",
    "9/14/2022",
    "9/15/2022",
    "9/16/2022",
    "9/17/2022",
    "9/18/2022",
    "9/19/2022",
    "9/20/2022",
    "9/21/2022",
    "9/22/2022",
    "9/23/2022",
    "9/24/2022",
    "9/25/2022",
    "9/26/2022",
    "9/27/2022",
    "9/28/2022",
    "9/29/2022",
    "9/30/2022",
    "10/1/2022",
    "10/2/2022",
    "10/3/2022",
    "10/4/2022",
    "10/5/2022",
    "10/6/2022",
    "10/7/2022",
    "10/8/2022",
    "10/9/2022",
    "10/10/2022",
    "10/11/2022",
    "10/12/2022",
    "10/13/2022",
    "10/14/2022",
    "10/15/2022",
    "10/16/2022",
    "10/17/2022",
    "10/18/2022",
    "10/19/2022",
    "10/20/2022",
    "10/21/2022",
    "10/22/2022",
    "10/23/2022",
    "10/24/2022",
    "10/25/2022",
    "10/26/2022",
    "10/27/2022",
    "10/28/2022",
    "10/29/2022",
    "10/30/2022",
    "10/31/2022",
    "11/1/2022",
    "11/2/2022",
    "11/3/2022",
    "11/4/2022",
    "11/5/2022",
    "11/6/2022",
    "11/7/2022",
    "11/8/2022",
    "11/9/2022",
    "11/10/2022",
    "11/11/2022",
    "11/12/2022",
    "11/13/2022",
    "11/14/2022",
    "11/15/2022",
    "11/16/2022",
    "11/17/2022",
    "11/18/2022",
    "11/19/2022",
    "11/20/2022",
    "11/21/2022",
    "11/22/2022",
    "11/23/2022",
    "11/24/2022",
    "11/25/2022",
    "11/26/2022",
    "11/27/2022",
    "11/28/2022",
    "11/29/2022",
    "11/30/2022",
    "12/1/2022",
    "12/2/2022",
    "12/3/2022",
    "12/4/2022",
    "12/5/2022",
    "12/6/2022",
    "12/7/2022",
    "12/8/2022",
    "12/9/2022",
    "12/10/2022",
    "12/11/2022",
    "12/12/2022",
    "12/13/2022",
    "12/14/2022",
    "12/15/2022",
    "12/16/2022",
    "12/17/2022",
    "12/18/2022",
    "12/19/2022",
    "12/20/2022",
    "12/21/2022",
    "12/22/2022",
    "12/23/2022",
    "12/24/2022",
    "12/25/2022",
    "12/26/2022",
    "12/27/2022",
    "12/28/2022",
    "12/29/2022",
    "12/30/2022",
    "12/31/2022",
    "1/1/2023",
    "1/2/2023",
    "1/3/2023",
    "1/4/2023",
    "1/5/2023",
    "1/6/2023",
    "1/7/2023",
    "1/8/2023",
    "1/9/2023",
    "1/10/2023",
    "1/11/2023",
    "1/12/2023",
    "1/13/2023",
    "1/14/2023",
    "1/15/2023",
    "1/16/2023",
    "1/17/2023",
    "1/18/2023",
    "1/19/2023",
    "1/20/2023",
    "1/21/2023",
    "1/22/2023",
    "1/23/2023",
    "1/24/2023",
    "1/25/2023",
    "1/26/2023",
    "1/27/2023",
    "1/28/2023",
    "1/29/2023",
    "1/30/2023",
    "1/31/2023",
    "2/1/2023",
    "2/2/2023",
    "2/3/2023",
    "2/4/2023",
    "2/5/2023",
    "2/6/2023",
    "2/7/2023",
    "2/8/2023",
    "2/9/2023",
    "2/10/2023",
    "2/11/2023",
    "2/12/2023",
    "2/13/2023",
    "2/14/2023",
    "2/15/2023",
    "2/16/2023",
    "2/17/2023",
    "2/18/2023",
    "2/19/2023",
    "2/20/2023",
    "2/21/2023",
    "2/22/2023",
    "2/23/2023",
    "2/24/2023",
    "2/25/2023",
    "2/26/2023",
    "2/27/2023",
    "2/28/2023",
    "3/1/2023",
    "3/2/2023",
    "3/3/2023",
    "3/4/2023",
    "3/5/2023",
    "3/6/2023",
    "3/7/2023",
    "3/8/2023",
    "3/9/2023",
    "3/10/2023",
    "3/11/2023",
    "3/12/2023",
    "3/13/2023",
    "3/14/2023",
    "3/15/2023",
    "3/16/2023",
    "3/17/2023",
    "3/18/2023",
    "3/19/2023",
    "3/20/2023",
    "3/21/2023",
    "3/22/2023",
    "3/23/2023",
    "3/24/2023",
    "3/25/2023",
    "3/26/2023",
    "3/27/2023",
    "3/28/2023",
    "3/29/2023",
    "3/30/2023",
    "3/31/2023",
    "4/1/2023",
    "4/2/2023",
    "4/3/2023",
    "4/4/2023",
    "4/5/2023",
    "4/6/2023",
    "4/7/2023",
    "4/8/2023",
    "4/9/2023",
    "4/10/2023",
    "4/11/2023",
    "4/12/2023",
    "4/13/2023",
    "4/14/2023",
    "4/15/2023",
    "4/16/2023",
    "4/17/2023",
    "4/18/2023",
    "4/19/2023",
    "4/20/2023",
    "4/21/2023",
    "4/22/2023",
    "4/23/2023",
    "4/24/2023",
    "4/25/2023",
    "4/26/2023",
    "4/27/2023",
    "4/28/2023",
    "4/29/2023",
    "4/30/2023",
    "5/1/2023",
    "5/2/2023",
    "5/3/2023",
    "5/4/2023",
    "5/5/2023",
    "5/6/2023",
    "5/7/2023",
    "5/8/2023",
    "5/9/2023",
    "5/10/2023",
    "5/11/2023",
    "5/12/2023",
    "5/13/2023",
    "5/14/2023",
    "5/15/2023",
    "5/16/2023",
    "5/17/2023",
    "5/18/2023",
    "5/19/2023",
    "5/20/2023",
    "5/21/2023",
    "5/22/2023",
    "5/23/2023",
    "5/24/2023",
    "5/25/2023",
    "5/26/2023",
    "5/27/2023",
    "5/28/2023",
    "5/29/2023",
    "5/30/2023",
    "5/31/2023",
    "6/1/2023",
    "6/2/2023",
    "6/3/2023",
    "6/4/2023",
    "6/5/2023",
    "6/6/2023",
    "6/7/2023",
    "6/8/2023",
    "6/9/2023",
    "6/10/2023",
    "6/11/2023",
    "6/12/2023",
    "6/13/2023",
    "6/14/2023",
    "6/15/2023",
    "6/16/2023",
    "6/17/2023",
    "6/18/2023",
    "6/19/2023",
    "6/20/2023",
    "6/21/2023",
    "6/22/2023",
    "6/23/2023",
    "6/24/2023",
    "6/25/2023",
    "6/26/2023",
    "6/27/2023",
    "6/28/2023",
    "6/29/2023",
    "6/30/2023",
    "7/1/2023",
    "7/2/2023",
    "7/3/2023",
    "7/4/2023",
    "7/5/2023",
    "7/6/2023",
    "7/7/2023",
    "7/8/2023",
    "7/9/2023",
    "7/10/2023",
    "7/11/2023",
    "7/12/2023",
    "7/13/2023",
    "7/14/2023",
    "7/15/2023",
    "7/16/2023",
    "7/17/2023",
    "7/18/2023",
    "7/19/2023",
    "7/20/2023",
    "7/21/2023",
    "7/22/2023",
    "7/23/2023",
    "7/24/2023",
    "7/25/2023",
    "7/26/2023",
    "7/27/2023",
    "7/28/2023",
    "7/29/2023",
    "7/30/2023",
    "7/31/2023",
    "8/1/2023",
    "8/2/2023",
    "8/3/2023",
    "8/4/2023",
    "8/5/2023",
    "8/6/2023",
    "8/7/2023",
    "8/8/2023",
    "8/9/2023",
    "8/10/2023",
    "8/11/2023",
    "8/12/2023",
    "8/13/2023",
    "8/14/2023",
    "8/15/2023",
    "8/16/2023",
    "8/17/2023",
    "8/18/2023",
    "8/19/2023",
    "8/20/2023",
    "8/21/2023",
    "8/22/2023",
    "8/23/2023",
    "8/24/2023",
    "8/25/2023",
    "8/26/2023",
    "8/27/2023",
    "8/28/2023",
    "8/29/2023",
    "8/30/2023",
    "8/31/2023",
    "9/1/2023",
    "9/2/2023",
    "9/3/2023",
    "9/4/2023",
    "9/5/2023",
    "9/6/2023",
    "9/7/2023",
    "9/8/2023",
    "9/9/2023",
    "9/10/2023",
    "9/11/2023",
    "9/12/2023",
    "9/13/2023",
    "9/14/2023",
    "9/15/2023",
    "9/16/2023",
    "9/17/2023",
    "9/18/2023",
    "9/19/2023",
    "9/20/2023",
    "9/21/2023",
    "9/22/2023",
    "9/23/2023",
    "9/24/2023",
    "9/25/2023",
    "9/26/2023",
    "9/27/2023",
    "9/28/2023",
    "9/29/2023",
    "9/30/2023",
    "10/1/2023",
    "10/2/2023",
    "10/3/2023",
    "10/4/2023",
    "10/5/2023",
    "10/6/2023",
    "10/7/2023",
    "10/8/2023",
    "10/9/2023",
    "10/10/2023",
    "10/11/2023",
    "10/12/2023",
    "10/13/2023",
    "10/14/2023",
    "10/15/2023",
    "10/16/2023",
    "10/17/2023",
    "10/18/2023",
    "10/19/2023",
    "10/20/2023",
    "10/21/2023",
    "10/22/2023",
    "10/23/2023",
    "10/24/2023",
    "10/25/2023",
    "10/26/2023",
    "10/27/2023",
    "10/28/2023",
    "10/29/2023",
    "10/30/2023",
    "10/31/2023",
    "11/1/2023",
    "11/2/2023",
    "11/3/2023",
    "11/4/2023",
    "11/5/2023",
    "11/6/2023",
    "11/7/2023",
    "11/8/2023",
    "11/9/2023",
    "11/10/2023",
    "11/11/2023",
    "11/12/2023",
    "11/13/2023",
    "11/14/2023",
    "11/15/2023",
    "11/16/2023",
    "11/17/2023",
    "11/18/2023",
    "11/19/2023",
    "11/20/2023",
    "11/21/2023",
    "11/22/2023",
    "11/23/2023",
    "11/24/2023",
    "11/25/2023",
    "11/26/2023",
    "11/27/2023",
    "11/28/2023",
    "11/29/2023",
    "11/30/2023",
    "12/1/2023",
    "12/2/2023",
    "12/3/2023",
    "12/4/2023",
    "12/5/2023",
    "12/6/2023",
    "12/7/2023",
    "12/8/2023",
    "12/9/2023",
    "12/10/2023",
    "12/11/2023",
    "12/12/2023",
    "12/13/2023",
    "12/14/2023",
    "12/15/2023",
    "12/16/2023",
    "12/17/2023",
    "12/18/2023",
    "12/19/2023",
    "12/20/2023",
    "12/21/2023",
    "12/22/2023",
    "12/23/2023",
    "12/24/2023",
    "12/25/2023",
    "12/26/2023",
    "12/27/2023",
    "12/28/2023",
    "12/29/2023",
    "12/30/2023",
    "12/31/2023",
    "1/1/2024",
    "1/2/2024",
    "1/3/2024",
    "1/4/2024",
    "1/5/2024",
    "1/6/2024",
    "1/7/2024",
    "1/8/2024",
    "1/9/2024",
    "1/10/2024",
    "1/11/2024",
    "1/12/2024",
    "1/13/2024",
    "1/14/2024",
    "1/15/2024",
    "1/16/2024",
    "1/17/2024",
    "1/18/2024",
    "1/19/2024",
    "1/20/2024",
    "1/21/2024",
    "1/22/2024",
    "1/23/2024",
    "1/24/2024",
    "1/25/2024",
    "1/26/2024",
    "1/27/2024",
    "1/28/2024",
    "1/29/2024",
    "1/30/2024",
    "1/31/2024",
    "2/1/2024",
    "2/2/2024",
    "2/3/2024",
    "2/4/2024",
    "2/5/2024",
    "2/6/2024",
    "2/7/2024",
    "2/8/2024",
    "2/9/2024",
    "2/10/2024",
    "2/11/2024",
    "2/12/2024",
    "2/13/2024",
    "2/14/2024",
    "2/15/2024",
    "2/16/2024",
    "2/17/2024",
    "2/18/2024",
    "2/19/2024",
    "2/20/2024",
    "2/21/2024",
    "2/22/2024",
    "2/23/2024",
    "2/24/2024",
    "2/25/2024",
    "2/26/2024",
    "2/27/2024",
    "2/28/2024",
    "2/29/2024",
    "3/1/2024",
    "3/2/2024",
    "3/3/2024",
    "3/4/2024",
    "3/5/2024",
    "3/6/2024",
    "3/7/2024",
    "3/8/2024",
    "3/9/2024",
    "3/10/2024",
    "3/11/2024",
    "3/12/2024",
    "3/13/2024",
    "3/14/2024",
    "3/15/2024",
    "3/16/2024",
    "3/17/2024",
    "3/18/2024",
    "3/19/2024",
    "3/20/2024",
    "3/21/2024",
    "3/22/2024",
    "3/23/2024",
    "3/24/2024",
    "3/25/2024",
    "3/26/2024",
    "3/27/2024",
    "3/28/2024",
    "3/29/2024",
    "3/30/2024",
    "3/31/2024",
    "4/1/2024",
    "4/2/2024",
    "4/3/2024",
    "4/4/2024",
    "4/5/2024",
    "4/6/2024",
    "4/7/2024",
    "4/8/2024",
    "4/9/2024",
    "4/10/2024",
    "4/11/2024",
    "4/12/2024",
    "4/13/2024",
    "4/14/2024",
    "4/15/2024",
    "4/16/2024",
    "4/17/2024",
    "4/18/2024",
    "4/19/2024",
    "4/20/2024",
    "4/21/2024",
    "4/22/2024",
    "4/23/2024",
    "4/24/2024",
    "4/25/2024",
    "4/26/2024",
    "4/27/2024",
    "4/28/2024",
    "4/29/2024",
    "4/30/2024",
    "5/1/2024",
    "5/2/2024",
    "5/3/2024",
    "5/4/2024",
    "5/5/2024",
    "5/6/2024",
    "5/7/2024",
    "5/8/2024",
    "5/9/2024",
    "5/10/2024",
    "5/11/2024",
    "5/12/2024",
    "5/13/2024",
    "5/14/2024",
    "5/15/2024",
    "5/16/2024",
    "5/17/2024",
    "5/18/2024",
    "5/19/2024",
    "5/20/2024",
    "5/21/2024",
    "5/22/2024",
    "5/23/2024",
    "5/24/2024",
    "5/25/2024",
    "5/26/2024",
    "5/27/2024",
    "5/28/2024",
    "5/29/2024",
    "5/30/2024",
    "5/31/2024",
    "6/1/2024",
    "6/2/2024",
    "6/3/2024",
    "6/4/2024",
    "6/5/2024",
    "6/6/2024",
    "6/7/2024",
    "6/8/2024",
    "6/9/2024",
    "6/10/2024",
    "6/11/2024",
    "6/12/2024",
    "6/13/2024",
    "6/14/2024",
    "6/15/2024",
    "6/16/2024",
    "6/17/2024",
    "6/18/2024",
    "6/19/2024",
    "6/20/2024",
    "6/21/2024",
    "6/22/2024",
    "6/23/2024",
    "6/24/2024",
    "6/25/2024",
    "6/26/2024",
    "6/27/2024",
    "6/28/2024",
    "6/29/2024",
    "6/30/2024",
    "7/1/2024",
    "7/2/2024",
    "7/3/2024",
    "7/4/2024",
    "7/5/2024",
    "7/6/2024",
    "7/7/2024",
    "7/8/2024",
    "7/9/2024",
    "7/10/2024",
    "7/11/2024",
    "7/12/2024",
    "7/13/2024",
    "7/14/2024",
    "7/15/2024",
    "7/16/2024",
    "7/17/2024",
    "7/18/2024",
    "7/19/2024",
    "7/20/2024",
    "7/21/2024",
    "7/22/2024",
    "7/23/2024",
    "7/24/2024",
    "7/25/2024",
    "7/26/2024",
    "7/27/2024",
    "7/28/2024",
    "7/29/2024",
    "7/30/2024",
    "7/31/2024",
    "8/1/2024",
    "8/2/2024",
    "8/3/2024",
    "8/4/2024",
    "8/5/2024",
    "8/6/2024",
    "8/7/2024",
    "8/8/2024",
    "8/9/2024",
    "8/10/2024",
    "8/11/2024",
    "8/12/2024",
    "8/13/2024",
    "8/14/2024",
    "8/15/2024",
    "8/16/2024",
    "8/17/2024",
    "8/18/2024",
    "8/19/2024",
    "8/20/2024",
    "8/21/2024",
    "8/22/2024",
    "8/23/2024",
    "8/24/2024",
    "8/25/2024",
    "8/26/2024",
    "8/27/2024",
    "8/28/2024",
    "8/29/2024",
    "8/30/2024",
    "8/31/2024",
    "9/1/2024",
    "9/2/2024",
    "9/3/2024",
    "9/4/2024",
    "9/5/2024",
    "9/6/2024",
    "9/7/2024",
    "9/8/2024",
    "9/9/2024",
    "9/10/2024",
    "9/11/2024",
    "9/12/2024",
    "9/13/2024",
    "9/14/2024",
    "9/15/2024",
    "9/16/2024",
    "9/17/2024",
    "9/18/2024",
    "9/19/2024",
    "9/20/2024",
    "9/21/2024",
    "9/22/2024",
    "9/23/2024",
    "9/24/2024",
    "9/25/2024",
    "9/26/2024",
    "9/27/2024",
    "9/28/2024",
    "9/29/2024",
    "9/30/2024",
  ],
  NetValue: [
    "1",
    "1",
    "1.0005",
    "1.001",
    "1.0016",
    "1.0031",
    "1.0046",
    "1.0051",
    "1.0057",
    "1.0072",
    "1.0077",
    "1.0072",
    "1.0078",
    "1.0073",
    "1.0088",
    "1.0083",
    "1.0078",
    "1.0094",
    "1.0099",
    "1.0114",
    "1.0109",
    "1.0115",
    "1.011",
    "1.0105",
    "1.01",
    "1.0118",
    "1.0116",
    "1.0134",
    "1.0132",
    "1.015",
    "1.0148",
    "1.0156",
    "1.0154",
    "1.0173",
    "1.017",
    "1.0168",
    "1.0176",
    "1.0185",
    "1.0193",
    "1.0211",
    "1.0209",
    "1.0227",
    "1.0225",
    "1.0233",
    "1.0252",
    "1.026",
    "1.0258",
    "1.0255",
    "1.0264",
    "1.0272",
    "1.028",
    "1.0288",
    "1.0307",
    "1.0325",
    "1.0323",
    "1.0321",
    "1.0322",
    "1.0343",
    "1.0344",
    "1.0355",
    "1.0355",
    "1.0377",
    "1.0398",
    "1.0399",
    "1.042",
    "1.0432",
    "1.0443",
    "1.0454",
    "1.0475",
    "1.0497",
    "1.0508",
    "1.052",
    "1.052",
    "1.0532",
    "1.0543",
    "1.0544",
    "1.0544",
    "1.0566",
    "1.0577",
    "1.0599",
    "1.06",
    "1.0601",
    "1.0623",
    "1.0623",
    "1.0645",
    "1.0657",
    "1.0668",
    "1.0666",
    "1.0685",
    "1.0694",
    "1.0702",
    "1.0721",
    "1.074",
    "1.076",
    "1.0779",
    "1.0777",
    "1.0775",
    "1.0772",
    "1.0781",
    "1.079",
    "1.0787",
    "1.0807",
    "1.0804",
    "1.0802",
    "1.08",
    "1.0798",
    "1.0796",
    "1.0804",
    "1.0802",
    "1.08",
    "1.0808",
    "1.0828",
    "1.0836",
    "1.0856",
    "1.0854",
    "1.0862",
    "1.086",
    "1.086",
    "1.087",
    "1.0881",
    "1.0881",
    "1.088",
    "1.088",
    "1.0902",
    "1.09",
    "1.0888",
    "1.0887",
    "1.0886",
    "1.0896",
    "1.0884",
    "1.0894",
    "1.0893",
    "1.0892",
    "1.0914",
    "1.0924",
    "1.0912",
    "1.0911",
    "1.091",
    "1.0899",
    "1.091",
    "1.0921",
    "1.0921",
    "1.093",
    "1.0941",
    "1.0952",
    "1.0974",
    "1.0984",
    "1.0984",
    "1.0994",
    "1.0982",
    "1.0992",
    "1.1002",
    "1.1012",
    "1.1011",
    "1.1022",
    "1.1043",
    "1.1064",
    "1.1097",
    "1.1097",
    "1.1107",
    "1.1117",
    "1.1128",
    "1.1139",
    "1.1149",
    "1.1161",
    "1.1171",
    "1.1205",
    "1.1226",
    "1.1237",
    "1.1259",
    "1.1315",
    "1.1349",
    "1.1393",
    "1.1426",
    "1.1448",
    "1.1459",
    "1.147",
    "1.147",
    "1.1446",
    "1.1491",
    "1.1491",
    "1.1491",
    "1.1512",
    "1.1512",
    "1.1511",
    "1.1522",
    "1.1544",
    "1.1555",
    "1.1555",
    "1.1578",
    "1.1565",
    "1.1559",
    "1.1559",
    "1.1559",
    "1.1547",
    "1.1592",
    "1.1602",
    "1.1614",
    "1.1636",
    "1.1658",
    "1.1657",
    "1.1679",
    "1.1654",
    "1.1688",
    "1.1665",
    "1.1687",
    "1.1756",
    "1.1731",
    "1.1767",
    "1.1801",
    "1.1836",
    "1.1811",
    "1.1764",
    "1.1846",
    "1.1881",
    "1.1809",
    "1.1961",
    "1.1949",
    "1.1913",
    "1.1996",
    "1.2067",
    "1.2078",
    "1.2054",
    "1.203",
    "1.203",
    "1.2065",
    "1.2077",
    "1.2077",
    "1.2077",
    "1.2077",
    "1.2089",
    "1.2089",
    "1.2089",
    "1.2137",
    "1.215",
    "1.215",
    "1.2162",
    "1.2198",
    "1.2259",
    "1.2296",
    "1.2321",
    "1.2329",
    "1.2353",
    "1.2385",
    "1.2462",
    "1.2459",
    "1.254",
    "1.2605",
    "1.2641",
    "1.2787",
    "1.2877",
    "1.2908",
    "1.2936",
    "1.3068",
    "1.316",
    "1.3257",
    "1.3281",
    "1.3304",
    "1.3353",
    "1.3378",
    "1.3488",
    "1.3572",
    "1.3607",
    "1.3653",
    "1.3708",
    "1.3717",
    "1.3698",
    "1.3758",
    "1.3747",
    "1.3748",
    "1.3752",
    "1.3775",
    "1.3707",
    "1.371",
    "1.3702",
    "1.371",
    "1.3721",
    "1.3741",
    "1.3753",
    "1.3775",
    "1.3783",
    "1.3826",
    "1.3877",
    "1.3876",
    "1.3892",
    "1.392",
    "1.3941",
    "1.3965",
    "1.3978",
    "1.3997",
    "1.4006",
    "1.4034",
    "1.4028",
    "1.4046",
    "1.4065",
    "1.4067",
    "1.4075",
    "1.4128",
    "1.4108",
    "1.4221",
    "1.4277",
    "1.4381",
    "1.4382",
    "1.4441",
    "1.4475",
    "1.4566",
    "1.4558",
    "1.4584",
    "1.4624",
    "1.4687",
    "1.4769",
    "1.4813",
    "1.4895",
    "1.4922",
    "1.4984",
    "1.5072",
    "1.5244",
    "1.5249",
    "1.5258",
    "1.5285",
    "1.5295",
    "1.5315",
    "1.5347",
    "1.5349",
    "1.535",
    "1.5348",
    "1.5351",
    "1.5358",
    "1.5365",
    "1.5374",
    "1.5392",
    "1.5406",
    "1.5437",
    "1.5453",
    "1.5477",
    "1.5509",
    "1.5535",
    "1.5571",
    "1.5603",
    "1.5647",
    "1.5652",
    "1.5683",
    "1.5689",
    "1.5691",
    "1.57",
    "1.5709",
    "1.5718",
    "1.5928",
    "1.618",
    "1.6191",
    "1.6198",
    "1.6201",
    "1.6207",
    "1.6209",
    "1.621",
    "1.6211",
    "1.6213",
    "1.6216",
    "1.6218",
    "1.6219",
    "1.6221",
    "1.6223",
    "1.6223",
    "1.6218",
    "1.6221",
    "1.6224",
    "1.6225",
    "1.6223",
    "1.622",
    "1.6218",
    "1.6217",
    "1.6219",
    "1.622",
    "1.6222",
    "1.6223",
    "1.6223",
    "1.6223",
    "1.6222",
    "1.6223",
    "1.6224",
    "1.6221",
    "1.6223",
    "1.6226",
    "1.6226",
    "1.6225",
    "1.6225",
    "1.6226",
    "1.6224",
    "1.6224",
    "1.6223",
    "1.6222",
    "1.6222",
    "1.6217",
    "1.6216",
    "1.6217",
    "1.6218",
    "1.6218",
    "1.6222",
    "1.6226",
    "1.6226",
    "1.6228",
    "1.623",
    "1.6233",
    "1.6237",
    "1.6238",
    "1.6237",
    "1.6235",
    "1.6239",
    "1.6238",
    "1.6229",
    "1.6218",
    "1.6235",
    "1.6237",
    "1.6241",
    "1.624",
    "1.6244",
    "1.6241",
    "1.6245",
    "1.6248",
    "1.6253",
    "1.6256",
    "1.6253",
    "1.626",
    "1.6262",
    "1.6267",
    "1.6268",
    "1.6271",
    "1.6274",
    "1.6282",
    "1.6294",
    "1.6298",
    "1.6303",
    "1.6325",
    "1.633",
    "1.6331",
    "1.6347",
    "1.6371",
    "1.6387",
    "1.6391",
    "1.639",
    "1.6405",
    "1.6413",
    "1.6436",
    "1.6457",
    "1.6481",
    "1.6489",
    "1.6508",
    "1.6497",
    "1.6501",
    "1.6514",
    "1.6532",
    "1.654",
    "1.6554",
    "1.6575",
    "1.6611",
    "1.6644",
    "1.6681",
    "1.6713",
    "1.6687",
    "1.6686",
    "1.6682",
    "1.6685",
    "1.6684",
    "1.668",
    "1.669",
    "1.6695",
    "1.6694",
    "1.6693",
    "1.6705",
    "1.67",
    "1.6712",
    "1.6724",
    "1.6727",
    "1.6716",
    "1.6722",
    "1.6721",
    "1.6727",
    "1.6731",
    "1.673",
    "1.6733",
    "1.6731",
    "1.6733",
    "1.6733",
    "1.6739",
    "1.6756",
    "1.677",
    "1.6777",
    "1.6783",
    "1.6824",
    "1.6865",
    "1.6881",
    "1.691",
    "1.6912",
    "1.6919",
    "1.6922",
    "1.6927",
    "1.6939",
    "1.6972",
    "1.6994",
    "1.7009",
    "1.7016",
    "1.703",
    "1.7066",
    "1.7088",
    "1.7102",
    "1.7149",
    "1.7158",
    "1.7194",
    "1.7254",
    "1.7258",
    "1.7273",
    "1.7282",
    "1.7301",
    "1.732",
    "1.7331",
    "1.7363",
    "1.7396",
    "1.7415",
    "1.7424",
    "1.743",
    "1.7449",
    "1.7481",
    "1.751",
    "1.7502",
    "1.7517",
    "1.7525",
    "1.7533",
    "1.7551",
    "1.756",
    "1.7551",
    "1.7558",
    "1.7561",
    "1.7566",
    "1.7578",
    "1.7582",
    "1.7587",
    "1.7595",
    "1.7595",
    "1.7631",
    "1.7617",
    "1.7627",
    "1.7623",
    "1.7632",
    "1.7626",
    "1.7632",
    "1.7673",
    "1.7668",
    "1.7674",
    "1.7664",
    "1.768",
    "1.7687",
    "1.7681",
    "1.769",
    "1.7689",
    "1.7687",
    "1.7685",
    "1.769",
    "1.7692",
    "1.769",
    "1.7691",
    "1.7694",
    "1.7696",
    "1.7709",
    "1.7711",
    "1.7709",
    "1.7712",
    "1.7718",
    "1.7717",
    "1.7719",
    "1.7721",
    "1.7736",
    "1.7744",
    "1.7737",
    "1.7737",
    "1.774",
    "1.7734",
    "1.775",
    "1.7745",
    "1.7767",
    "1.7756",
    "1.7781",
    "1.7774",
    "1.7779",
    "1.777",
    "1.7787",
    "1.7803",
    "1.7796",
    "1.7796",
    "1.7786",
    "1.7807",
    "1.7802",
    "1.7802",
    "1.781",
    "1.782",
    "1.7807",
    "1.7811",
    "1.7819",
    "1.7814",
    "1.7809",
    "1.7815",
    "1.7823",
    "1.7814",
    "1.7815",
    "1.7815",
    "1.7812",
    "1.7825",
    "1.7822",
    "1.7823",
    "1.7827",
    "1.7825",
    "1.7844",
    "1.7853",
    "1.7858",
    "1.7855",
    "1.7856",
    "1.7868",
    "1.7877",
    "1.7873",
    "1.7866",
    "1.7876",
    "1.7906",
    "1.7891",
    "1.7894",
    "1.7896",
    "1.792",
    "1.7915",
    "1.7917",
    "1.7926",
    "1.7904",
    "1.7922",
    "1.7926",
    "1.7921",
    "1.7916",
    "1.7937",
    "1.7935",
    "1.7945",
    "1.7955",
    "1.795",
    "1.7964",
    "1.7957",
    "1.7967",
    "1.7983",
    "1.7996",
    "1.8006",
    "1.8005",
    "1.7999",
    "1.8008",
    "1.8012",
    "1.8023",
    "1.8038",
    "1.8033",
    "1.8042",
    "1.8054",
    "1.8075",
    "1.808",
    "1.8097",
    "1.8108",
    "1.8125",
    "1.813",
    "1.8149",
    "1.8185",
    "1.8189",
    "1.8212",
    "1.8233",
    "1.8235",
    "1.8252",
    "1.8249",
    "1.8252",
    "1.8265",
    "1.8268",
    "1.8271",
    "1.8281",
    "1.8281",
    "1.8296",
    "1.831",
    "1.8308",
    "1.8329",
    "1.8348",
    "1.8344",
    "1.8359",
    "1.8358",
    "1.8367",
    "1.8367",
    "1.8381",
    "1.8388",
    "1.8391",
    "1.8398",
    "1.8397",
    "1.8401",
    "1.8406",
    "1.8405",
    "1.8414",
    "1.8421",
    "1.8412",
    "1.8407",
    "1.8414",
    "1.841",
    "1.8432",
    "1.8421",
    "1.8417",
    "1.8415",
    "1.8403",
    "1.8393",
    "1.8387",
    "1.8729",
    "1.8743",
    "1.8753",
    "1.8767",
    "1.8782",
    "1.878",
    "1.8782",
    "1.8779",
    "1.8801",
    "1.8799",
    "1.88",
    "1.88",
    "1.8794",
    "1.8839",
    "1.8847",
    "1.883",
    "1.8863",
    "1.888",
    "1.8897",
    "1.8933",
    "1.8946",
    "1.8967",
    "1.9021",
    "1.9068",
    "1.9091",
    "1.9104",
    "1.9132",
    "1.913",
    "1.9138",
    "1.9146",
    "1.9154",
    "1.9161",
    "1.9166",
    "1.9192",
    "1.9239",
    "1.9261",
    "1.9274",
    "1.9303",
    "1.9318",
    "1.9345",
    "1.9351",
    "1.936",
    "1.9355",
    "1.9367",
    "1.937",
    "1.9424",
    "1.9462",
    "1.9444",
    "1.9452",
    "1.9456",
    "1.9457",
    "1.9451",
    "1.9454",
    "1.9468",
    "1.9485",
    "1.9513",
    "1.9501",
    "1.9521",
    "1.9534",
    "1.9548",
    "1.9574",
    "1.9575",
    "1.9601",
    "1.959",
    "1.9637",
    "1.9636",
    "1.9651",
    "1.9674",
    "1.971",
    "1.9729",
    "1.9731",
    "1.973",
    "1.9736",
    "1.9746",
    "1.9737",
    "1.9735",
    "1.9738",
    "1.9743",
    "1.9741",
    "1.9748",
    "1.975",
    "1.9752",
    "1.9754",
    "1.9765",
    "1.9769",
    "1.9771",
    "1.9779",
    "1.979",
    "1.9795",
    "1.9797",
    "1.9807",
    "1.9808",
    "1.9818",
    "1.9822",
    "1.9825",
    "1.9824",
    "1.983",
    "1.9834",
    "1.9836",
    "1.9837",
    "1.9835",
    "1.9837",
    "1.9839",
    "1.9841",
    "1.9843",
    "1.9846",
    "1.9848",
    "1.9849",
    "1.9849",
    "1.9849",
    "1.985",
    "1.9856",
    "1.9856",
    "1.9857",
    "1.9858",
    "1.9859",
    "1.9861",
    "1.9862",
    "1.9862",
    "1.986",
    "1.9861",
    "1.9864",
    "1.9866",
    "1.9876",
    "1.9886",
    "1.9883",
    "1.9892",
    "1.9923",
    "1.9911",
    "1.9909",
    "1.9909",
    "1.992",
    "1.9932",
    "1.992",
    "1.9997",
    "1.9999",
    "2.0005",
    "2.0016",
    "2.0027",
    "2.0038",
    "2.0051",
    "2.0059",
    "2.0063",
    "2.0065",
    "2.0069",
    "2.0095",
    "2.0119",
    "2.0127",
    "2.0137",
    "2.0147",
    "2.0152",
    "2.0158",
    "2.0157",
    "2.0172",
    "2.0182",
    "2.0188",
    "2.0192",
    "2.0196",
    "2.0199",
    "2.0207",
    "2.021",
    "2.0207",
    "2.0208",
    "2.021",
    "2.0213",
    "2.0221",
    "2.0226",
    "2.0235",
    "2.0233",
    "2.0237",
    "2.0239",
    "2.0243",
    "2.0245",
    "2.0255",
    "2.0257",
    "2.0264",
    "2.0167",
    "2.0172",
    "2.0176",
    "2.0179",
    "2.0182",
    "2.02",
    "2.0119",
    "2.0303",
    "2.0331",
    "2.0323",
    "2.031",
    "2.0364",
    "2.0413",
    "2.0416",
    "2.0432",
    "2.0458",
    "2.0466",
    "2.0458",
    "2.0571",
    "2.0585",
    "2.0585",
    "2.0607",
    "2.0613",
    "2.0619",
    "2.0619",
    "2.0621",
    "2.0619",
    "2.0615",
    "2.0617",
    "2.0639",
    "2.0652",
    "2.0651",
    "2.0656",
    "2.066",
    "2.0672",
    "2.0681",
    "2.0681",
    "2.0692",
    "2.0705",
    "2.0698",
    "2.0721",
    "2.073",
    "2.0725",
    "2.0703",
    "2.0716",
    "2.073",
    "2.0723",
    "2.0772",
    "2.0783",
    "2.0799",
    "2.0815",
    "2.0817",
    "2.0818",
    "2.0829",
    "2.0828",
    "2.0825",
    "2.0833",
    "2.0842",
    "2.0847",
    "2.0849",
    "2.085",
    "2.0855",
    "2.0858",
    "2.0874",
    "2.0878",
    "2.089",
    "2.0899",
    "2.0907",
    "2.0915",
    "2.0922",
    "2.0922",
    "2.093",
    "2.0955",
    "2.0988",
    "2.1017",
    "2.1019",
    "2.1024",
    "2.1048",
    "2.1043",
    "2.1067",
    "2.1088",
    "2.1099",
    "2.1121",
    "2.1127",
    "2.1122",
    "2.1127",
    "2.1142",
    "2.1147",
    "2.1164",
    "2.1167",
    "2.1178",
    "2.1186",
    "2.122",
    "2.1234",
    "2.1269",
    "2.1294",
    "2.1293",
    "2.1322",
    "2.1336",
    "2.133",
    "2.1339",
    "2.1331",
    "2.134",
    "2.1342",
    "2.1341",
    "2.1365",
    "2.1372",
    "2.1382",
    "2.1397",
    "2.1414",
    "2.1433",
    "2.1442",
    "2.1444",
    "2.1453",
    "2.1448",
    "2.1456",
    "2.1464",
    "2.1473",
    "2.1479",
    "2.1486",
    "2.1491",
    "2.1489",
    "2.1488",
    "2.1489",
    "2.1485",
    "2.1484",
    "2.1484",
    "2.1481",
    "2.1478",
    "2.1494",
    "2.1484",
    "2.1489",
    "2.1487",
    "2.1488",
    "2.1495",
    "2.1494",
    "2.1491",
    "2.1497",
    "2.1497",
    "2.1505",
    "2.1506",
    "2.1509",
    "2.1511",
    "2.1518",
    "2.1516",
    "2.1522",
    "2.1527",
    "2.1525",
    "2.1531",
    "2.1536",
    "2.1543",
    "2.1543",
    "2.1552",
    "2.1552",
    "2.1555",
    "2.1559",
    "2.1561",
    "2.1567",
    "2.1571",
    "2.1575",
    "2.1583",
    "2.158",
    "2.159",
    "2.1607",
    "2.1628",
    "2.1656",
    "2.167",
    "2.1706",
    "2.1718",
    "2.1725",
    "2.1722",
    "2.1728",
    "2.1733",
    "2.1733",
    "2.1739",
    "2.1745",
    "2.1753",
    "2.1753",
    "2.1761",
    "2.177",
    "2.178",
    "2.1786",
    "2.1791",
    "2.18",
    "2.1809",
    "2.1812",
    "2.1818",
    "2.1812",
    "2.181",
    "2.1811",
    "2.1818",
    "2.1823",
    "2.1821",
    "2.1821",
    "2.1826",
    "2.1835",
    "2.1836",
    "2.1838",
    "2.1845",
    "2.185",
    "2.1853",
    "2.1863",
    "2.1867",
    "2.1868",
    "2.1873",
    "2.1879",
    "2.1887",
    "2.1895",
    "2.1909",
    "2.1912",
    "2.1916",
    "2.1921",
    "2.1928",
    "2.1936",
    "2.1961",
    "2.1946",
    "2.1956",
    "2.1956",
    "2.1953",
    "2.196",
    "2.1953",
    "2.196",
    "2.196",
    "2.1958",
    "2.1967",
    "2.1973",
    "2.1973",
    "2.1972",
    "2.1977",
    "2.1977",
    "2.1974",
    "2.1966",
    "2.1971",
    "2.1976",
    "2.197",
    "2.1971",
    "2.1972",
    "2.197",
    "2.1973",
    "2.1973",
    "2.1991",
    "2.2006",
    "2.2018",
    "2.2047",
    "2.2059",
    "2.2047",
    "2.2055",
    "2.2061",
    "2.2066",
    "2.2081",
    "2.2086",
    "2.2092",
    "2.2114",
    "2.216",
    "2.2156",
    "2.2176",
    "2.2188",
    "2.2196",
    "2.2213",
    "2.2215",
    "2.2234",
    "2.224",
    "2.2258",
    "2.2261",
    "2.2266",
    "2.2263",
    "2.2258",
    "2.2277",
    "2.2284",
    "2.2297",
    "2.2311",
    "2.2313",
    "2.2319",
    "2.2324",
    "2.2326",
    "2.2331",
    "2.233",
    "2.2335",
    "2.2336",
    "2.2337",
    "2.234",
    "2.2343",
    "2.2346",
    "2.2349",
    "2.2355",
    "2.2361",
    "2.2363",
    "2.2364",
    "2.2361",
    "2.2364",
    "2.2363",
    "2.2363",
    "2.2363",
    "2.2367",
    "2.2366",
    "2.2366",
    "2.2368",
    "2.2368",
    "2.2372",
    "2.2373",
    "2.2375",
    "2.2374",
    "2.2375",
    "2.2375",
    "2.2377",
    "2.238",
    "2.2383",
    "2.2384",
    "2.2387",
    "2.2389",
    "2.2388",
    "2.2388",
    "2.2387",
    "2.2386",
    "2.2391",
    "2.2394",
    "2.2396",
    "2.2399",
    "2.24",
    "2.2406",
    "2.2409",
    "2.2411",
    "2.2418",
    "2.2424",
    "2.2425",
    "2.2425",
    "2.2437",
    "2.2443",
    "2.2447",
    "2.2449",
    "2.2452",
    "2.2456",
    "2.2467",
    "2.2474",
    "2.2475",
    "2.2479",
    "2.2482",
    "2.2485",
    "2.2484",
    "2.2488",
    "2.249",
    "2.2493",
    "2.2494",
    "2.2495",
    "2.2497",
    "2.2499",
    "2.2501",
    "2.2505",
    "2.2507",
    "2.251",
    "2.2512",
    "2.2523",
    "2.253",
    "2.2525",
    "2.2547",
    "2.2553",
    "2.257",
    "2.2591",
    "2.2615",
    "2.2629",
    "2.2645",
    "2.2671",
    "2.2696",
    "2.2704",
    "2.2748",
    "2.2769",
    "2.2782",
    "2.283",
    "2.2846",
    "2.2879",
    "2.2892",
    "2.2926",
    "2.2946",
    "2.2976",
    "2.3018",
    "2.307",
    "2.31",
    "2.3101",
    "2.3139",
    "2.3147",
    "2.3145",
    "2.3154",
    "2.3168",
    "2.3195",
    "2.3192",
    "2.3208",
    "2.3221",
    "2.3234",
    "2.3257",
    "2.3275",
    "2.3277",
    "2.3303",
    "2.3309",
    "2.3315",
    "2.3323",
    "2.3351",
    "2.338",
    "2.3408",
    "2.3454",
    "2.3463",
    "2.3501",
    "2.3561",
    "2.3591",
    "2.3626",
    "2.3638",
    "2.3655",
    "2.3669",
    "2.3709",
    "2.3733",
    "2.3762",
    "2.3775",
    "2.378",
    "2.3786",
    "2.3812",
    "2.3852",
    "2.3896",
    "2.394",
    "2.3968",
    "2.4044",
    "2.4056",
    "2.4142",
    "2.4198",
    "2.4253",
    "2.43",
    "2.4328",
    "2.4388",
    "2.4458",
    "2.447",
    "2.4479",
    "2.4477",
    "2.4488",
    "2.4465",
    "2.4475",
    "2.4482",
    "2.4489",
    "2.4505",
    "2.4511",
    "2.4524",
    "2.4535",
    "2.4541",
    "2.4543",
    "2.4562",
    "2.4572",
    "2.458",
    "2.4591",
    "2.4591",
    "2.4596",
    "2.4598",
    "2.4606",
    "2.4609",
    "2.4623",
    "2.4626",
    "2.4629",
    "2.4632",
    "2.4642",
    "2.4647",
    "2.4653",
    "2.4666",
    "2.4674",
    "2.4684",
    "2.469",
    "2.47",
    "2.4703",
    "2.4704",
    "2.4723",
    "2.4741",
    "2.4748",
    "2.479",
    "2.482",
    "2.4864",
    "2.4921",
    "2.496",
    "2.498",
    "2.5006",
    "2.503",
    "2.5045",
    "2.507",
    "2.5098",
    "2.5122",
    "2.5157",
    "2.5201",
    "2.5265",
    "2.5336",
    "2.5408",
    "2.5507",
    "2.5605",
    "2.5727",
    "2.5878",
    "2.602",
    "2.6144",
    "2.6264",
    "2.6387",
    "2.6524",
    "2.6636",
    "2.6752",
    "2.6881",
    "2.6984",
    "2.708",
    "2.7156",
    "2.7216",
    "2.7267",
    "2.7317",
    "2.7332",
    "2.736",
    "2.738",
    "2.7424",
    "2.7448",
    "2.7488",
    "2.7503",
    "2.7536",
    "2.7573",
    "2.7629",
    "2.7696",
    "2.7769",
    "2.7829",
    "2.7927",
    "2.7951",
    "2.7976",
    "2.7987",
    "2.8002",
    "2.8016",
    "2.8027",
    "2.804",
    "2.8082",
    "2.8091",
    "2.8111",
    "2.8138",
    "2.8132",
    "2.8129",
    "2.8127",
    "2.8126",
    "2.8125",
    "2.8125",
    "2.8124",
    "2.8124",
    "2.8124",
    "2.8125",
    "2.8121",
    "2.8131",
    "2.8131",
    "2.8131",
    "2.8144",
    "2.8137",
    "2.8128",
    "2.8132",
    "2.8131",
    "2.8139",
    "2.8147",
    "2.8148",
    "2.8152",
    "2.8162",
    "2.8165",
    "2.8175",
    "2.8178",
    "2.8179",
    "2.818",
    "2.8181",
    "2.8177",
    "2.819",
    "2.8191",
    "2.8199",
    "2.8206",
    "2.8206",
    "2.8217",
    "2.8212",
    "2.8239",
    "2.8259",
    "2.8283",
    "2.8291",
    "2.8309",
    "2.8327",
    "2.834",
    "2.8365",
    "2.8423",
    "2.8439",
    "2.8455",
    "2.8465",
    "2.8497",
    "2.8511",
    "2.8526",
    "2.8536",
    "2.8577",
    "2.8601",
    "2.8598",
    "2.8594",
    "2.8594",
    "2.8595",
    "2.8588",
    "2.8594",
    "2.8597",
    "2.8597",
    "2.8596",
    "2.8603",
    "2.8591",
    "2.8594",
    "2.8588",
    "2.8595",
    "2.8594",
    "2.8589",
    "2.8602",
    "2.8596",
    "2.8603",
    "2.8604",
    "2.861",
    "2.8608",
    "2.8607",
    "2.8609",
    "2.8618",
    "2.862",
    "2.862",
    "2.8617",
    "2.8613",
    "2.8614",
    "2.8618",
    "2.8618",
    "2.8617",
    "2.862",
    "2.8621",
    "2.8621",
    "2.8622",
    "2.8623",
    "2.863",
    "2.8641",
    "2.8651",
    "2.8651",
    "2.866",
    "2.8664",
    "2.867",
    "2.8679",
    "2.868",
    "2.8687",
    "2.869",
    "2.8696",
    "2.8708",
    "2.8714",
    "2.8721",
    "2.8722",
    "2.8723",
    "2.8721",
    "2.8713",
    "2.8701",
    "2.8661",
    "2.8648",
    "2.8648",
    "2.8658",
    "2.8673",
    "2.8681",
    "2.8688",
    "2.8698",
    "2.87",
    "2.8713",
    "2.872",
    "2.8725",
    "2.8731",
    "2.8736",
    "2.8745",
    "2.8765",
    "2.8774",
    "2.8795",
    "2.8811",
    "2.8837",
    "2.8851",
    "2.8858",
    "2.8871",
    "2.8876",
    "2.8888",
    "2.8892",
    "2.889",
    "2.8904",
    "2.8898",
    "2.89",
    "2.8891",
    "2.8891",
    "2.8896",
    "2.889",
    "2.8899",
    "2.8892",
    "2.8905",
    "2.8902",
    "2.8903",
    "2.8935",
    "2.893",
    "2.8948",
    "2.8949",
    "2.8941",
    "2.8938",
    "2.8982",
    "2.8983",
    "2.8999",
    "2.9006",
    "2.901",
    "2.9046",
    "2.9031",
    "2.9052",
    "2.9079",
    "2.9093",
    "2.9086",
    "2.9099",
  ],
  Price: [
    "9758.85",
    "9771.49",
    "9795.70",
    "9870.10",
    "9321.78",
    "9480.84",
    "9475.28",
    "9386.79",
    "9450.70",
    "9538.02",
    "9480.26",
    "9411.84",
    "9288.02",
    "9332.34",
    "9303.63",
    "9648.72",
    "9629.66",
    "9313.61",
    "9264.81",
    "9162.92",
    "9045.39",
    "9143.58",
    "9190.85",
    "9137.99",
    "9228.33",
    "9123.41",
    "9087.30",
    "9132.49",
    "9073.94",
    "9375.48",
    "9252.28",
    "9428.33",
    "9277.97",
    "9278.81",
    "9240.35",
    "9276.50",
    "9243.61",
    "9243.21",
    "9192.84",
    "9132.23",
    "9151.39",
    "9159.04",
    "9185.82",
    "9164.23",
    "9374.89",
    "9525.36",
    "9581.07",
    "9536.89",
    "9677.11",
    "9905.17",
    "10990.87",
    "10912.82",
    "11100.47",
    "11111.21",
    "11323.47",
    "11759.59",
    "11053.61",
    "11246.35",
    "11205.89",
    "11747.02",
    "11779.77",
    "11601.47",
    "11754.05",
    "11675.74",
    "11878.11",
    "11410.53",
    "11584.93",
    "11784.14",
    "11768.87",
    "11865.70",
    "11892.80",
    "12254.40",
    "11991.23",
    "11758.28",
    "11878.37",
    "11592.49",
    "11681.83",
    "11664.85",
    "11774.60",
    "11366.13",
    "11488.36",
    "11323.40",
    "11542.50",
    "11506.87",
    "11711.51",
    "11680.82",
    "11970.48",
    "11414.03",
    "10245.30",
    "10511.81",
    "10169.57",
    "10280.35",
    "10369.56",
    "10131.52",
    "10242.35",
    "10363.14",
    "10400.92",
    "10442.17",
    "10323.76",
    "10680.84",
    "10796.95",
    "10974.91",
    "10948.99",
    "10944.59",
    "11094.35",
    "10938.27",
    "10462.26",
    "10538.46",
    "10246.19",
    "10760.07",
    "10692.72",
    "10750.72",
    "10775.27",
    "10709.65",
    "10844.64",
    "10784.49",
    "10619.45",
    "10575.97",
    "10549.33",
    "10669.58",
    "10793.34",
    "10604.41",
    "10668.97",
    "10915.69",
    "11064.46",
    "11296.36",
    "11384.18",
    "11555.36",
    "11425.90",
    "11429.51",
    "11495.35",
    "11322.12",
    "11358.10",
    "11483.36",
    "11742.04",
    "11916.33",
    "12823.69",
    "12965.89",
    "12931.54",
    "13108.06",
    "13031.17",
    "13075.25",
    "13654.22",
    "13271.29",
    "13437.88",
    "13546.52",
    "13781.00",
    "13737.11",
    "13550.49",
    "13950.30",
    "14133.71",
    "15579.85",
    "15565.88",
    "14833.75",
    "15479.57",
    "15332.32",
    "15290.90",
    "15701.34",
    "16276.34",
    "16317.81",
    "16068.14",
    "15955.59",
    "16716.11",
    "17645.41",
    "17804.01",
    "17817.09",
    "18621.31",
    "18642.23",
    "18370.00",
    "18364.12",
    "19107.46",
    "18732.12",
    "17150.62",
    "17108.40",
    "17717.41",
    "18177.48",
    "19625.84",
    "18803.00",
    "19201.09",
    "19445.40",
    "18699.77",
    "19154.23",
    "19345.12",
    "19191.63",
    "18321.14",
    "18553.92",
    "18264.99",
    "18058.90",
    "18803.66",
    "19142.38",
    "19246.64",
    "19417.08",
    "21310.60",
    "22805.16",
    "23137.96",
    "23869.83",
    "23477.29",
    "22803.08",
    "23783.03",
    "23241.35",
    "23735.95",
    "24664.79",
    "26437.04",
    "26272.29",
    "27084.81",
    "27362.44",
    "28840.95",
    "29001.72",
    "29374.15",
    "32127.27",
    "32782.02",
    "31971.91",
    "33992.43",
    "36824.36",
    "39371.04",
    "40797.61",
    "40254.55",
    "38356.44",
    "35566.66",
    "33922.96",
    "37316.36",
    "39187.33",
    "36825.37",
    "36178.14",
    "35791.28",
    "36630.07",
    "36069.80",
    "35547.75",
    "30825.70",
    "33005.76",
    "32067.64",
    "32289.38",
    "32366.39",
    "32569.85",
    "30432.55",
    "33466.10",
    "34316.39",
    "34269.52",
    "33114.36",
    "33537.18",
    "35510.29",
    "37472.09",
    "36926.07",
    "38144.31",
    "39266.01",
    "38903.44",
    "46196.46",
    "46481.11",
    "44918.18",
    "47909.33",
    "47504.85",
    "47105.52",
    "48717.29",
    "47945.06",
    "49199.87",
    "52149.01",
    "51679.80",
    "55888.13",
    "56099.52",
    "57539.95",
    "54207.32",
    "48824.43",
    "49705.33",
    "47093.85",
    "46339.76",
    "46188.45",
    "45137.77",
    "49631.24",
    "48378.99",
    "50538.24",
    "48561.17",
    "48927.30",
    "48912.38",
    "51206.69",
    "52246.52",
    "54824.12",
    "56008.55",
    "57805.12",
    "57332.09",
    "61243.09",
    "59302.32",
    "55907.20",
    "56804.90",
    "58870.89",
    "57858.92",
    "58346.65",
    "58313.64",
    "57523.42",
    "54529.14",
    "54738.95",
    "52774.27",
    "51704.16",
    "55137.31",
    "55973.51",
    "55950.75",
    "57750.20",
    "58917.69",
    "58918.83",
    "59095.81",
    "59384.31",
    "57603.89",
    "58758.55",
    "59057.88",
    "58192.36",
    "56048.94",
    "58323.95",
    "58245.00",
    "59793.23",
    "60204.96",
    "59893.45",
    "63503.46",
    "63109.70",
    "63314.01",
    "61572.79",
    "60683.82",
    "56216.18",
    "55724.27",
    "56473.03",
    "53906.09",
    "51762.27",
    "51093.65",
    "50050.87",
    "49004.25",
    "54021.75",
    "55033.12",
    "54824.70",
    "53555.11",
    "57750.18",
    "57828.05",
    "56631.08",
    "57200.29",
    "53333.54",
    "57424.01",
    "56396.52",
    "57356.40",
    "58803.78",
    "58232.32",
    "55859.80",
    "56704.57",
    "49150.54",
    "49716.19",
    "49880.54",
    "46760.19",
    "46456.06",
    "43537.51",
    "42909.40",
    "37002.44",
    "40782.74",
    "37304.69",
    "37536.63",
    "34770.58",
    "38705.98",
    "38402.22",
    "39294.20",
    "38436.97",
    "35697.61",
    "34616.07",
    "35678.13",
    "37332.86",
    "36684.93",
    "37575.18",
    "39208.77",
    "36894.41",
    "35551.96",
    "35862.38",
    "33560.71",
    "33472.63",
    "37345.12",
    "36702.60",
    "37334.40",
    "35552.52",
    "39097.86",
    "40218.48",
    "40406.27",
    "38347.06",
    "38053.50",
    "35787.25",
    "35615.87",
    "35698.30",
    "31676.69",
    "32505.66",
    "33723.03",
    "34662.44",
    "31637.78",
    "32186.28",
    "34649.64",
    "34434.34",
    "35867.78",
    "35040.84",
    "33572.12",
    "33897.05",
    "34668.55",
    "35287.78",
    "33746.00",
    "34235.20",
    "33855.33",
    "32877.37",
    "33798.01",
    "33520.52",
    "34240.19",
    "33155.85",
    "32702.03",
    "32822.35",
    "31780.73",
    "31421.54",
    "31533.07",
    "31796.81",
    "30817.83",
    "29807.35",
    "32110.69",
    "32313.11",
    "33581.55",
    "34292.45",
    "35350.19",
    "37337.54",
    "39406.94",
    "39995.91",
    "40008.42",
    "42235.55",
    "41626.20",
    "39974.89",
    "39201.95",
    "38152.98",
    "39747.50",
    "40869.55",
    "42816.50",
    "44555.80",
    "43798.12",
    "46365.40",
    "45585.03",
    "45593.64",
    "44428.29",
    "47793.32",
    "47096.95",
    "47047.00",
    "46004.48",
    "44695.36",
    "44801.19",
    "46717.58",
    "49339.18",
    "48905.49",
    "49321.65",
    "49546.15",
    "47706.12",
    "48960.79",
    "46942.22",
    "49058.67",
    "48902.40",
    "48829.83",
    "47054.98",
    "47166.69",
    "48847.03",
    "49327.72",
    "50025.38",
    "49944.63",
    "51753.41",
    "52633.54",
    "46811.13",
    "46091.39",
    "46391.42",
    "44883.91",
    "45201.46",
    "46063.27",
    "44963.07",
    "47092.49",
    "48176.35",
    "47783.36",
    "47267.52",
    "48278.36",
    "47260.22",
    "42843.80",
    "40693.68",
    "43574.51",
    "44895.10",
    "42839.75",
    "42716.59",
    "43208.54",
    "42235.73",
    "41034.54",
    "41564.36",
    "43790.89",
    "48116.94",
    "47711.49",
    "48199.95",
    "49112.90",
    "51514.81",
    "55361.45",
    "53805.98",
    "53967.85",
    "54968.22",
    "54771.58",
    "57484.79",
    "56041.06",
    "57401.10",
    "57321.52",
    "61593.95",
    "60892.18",
    "61553.62",
    "62026.08",
    "64261.99",
    "65992.84",
    "62210.17",
    "60692.27",
    "61393.62",
    "60930.84",
    "63039.82",
    "60363.79",
    "58482.39",
    "60622.14",
    "62227.96",
    "61888.83",
    "61318.96",
    "61004.41",
    "63226.40",
    "62970.05",
    "61452.23",
    "61125.68",
    "61527.48",
    "63326.99",
    "67566.83",
    "66971.83",
    "64995.23",
    "64949.96",
    "64155.94",
    "64469.53",
    "65466.84",
    "63557.87",
    "60161.25",
    "60368.01",
    "56942.14",
    "58119.58",
    "59697.20",
    "58730.48",
    "56289.29",
    "57569.07",
    "56280.43",
    "57274.68",
    "53569.77",
    "54815.08",
    "57248.46",
    "57806.57",
    "57005.43",
    "57229.83",
    "56477.82",
    "53598.25",
    "49200.70",
    "49368.85",
    "50582.63",
    "50700.09",
    "50504.80",
    "47672.12",
    "47243.30",
    "49362.51",
    "50098.34",
    "46737.48",
    "46612.63",
    "48896.72",
    "47665.43",
    "46202.14",
    "46848.78",
    "46707.02",
    "46880.28",
    "48936.61",
    "48628.51",
    "50784.54",
    "50822.20",
    "50429.86",
    "50809.52",
    "50640.42",
    "47588.86",
    "46444.71",
    "47178.13",
    "46306.45",
    "47686.81",
    "47345.22",
    "46458.12",
    "45897.57",
    "43569.00",
    "43160.93",
    "41557.90",
    "41733.94",
    "41911.60",
    "41821.26",
    "42735.86",
    "43949.10",
    "42591.57",
    "43099.70",
    "43177.40",
    "43113.88",
    "42250.55",
    "42375.63",
    "41744.33",
    "40680.42",
    "36457.32",
    "35030.25",
    "36276.80",
    "36654.33",
    "36954.00",
    "36852.12",
    "37138.23",
    "37784.33",
    "38138.18",
    "37917.60",
    "38483.13",
    "38743.27",
    "36952.98",
    "37154.60",
    "41500.88",
    "41441.16",
    "42412.43",
    "43840.29",
    "44118.45",
    "44338.80",
    "43565.11",
    "42407.94",
    "42244.47",
    "42197.52",
    "42586.92",
    "44575.20",
    "43961.86",
    "40538.01",
    "40030.98",
    "40122.16",
    "38431.38",
    "37075.28",
    "38286.03",
    "37296.57",
    "38332.61",
    "39214.22",
    "39105.15",
    "37709.79",
    "43193.23",
    "44354.64",
    "43924.12",
    "42451.79",
    "39137.61",
    "39400.59",
    "38419.98",
    "38062.04",
    "38737.27",
    "41982.93",
    "39437.46",
    "38794.97",
    "38904.01",
    "37849.66",
    "39666.75",
    "39338.79",
    "41143.93",
    "40951.38",
    "41801.16",
    "42190.65",
    "41247.82",
    "41078.00",
    "42358.81",
    "42892.96",
    "43960.93",
    "44348.73",
    "44500.83",
    "46820.49",
    "47128.00",
    "47465.73",
    "47062.66",
    "45538.68",
    "46281.64",
    "45868.95",
    "46453.57",
    "46622.68",
    "45555.99",
    "43206.74",
    "43503.85",
    "42287.66",
    "42782.14",
    "42207.67",
    "39521.90",
    "40127.18",
    "41166.73",
    "39935.52",
    "40553.46",
    "40424.48",
    "39716.95",
    "40826.21",
    "41502.75",
    "41374.38",
    "40527.36",
    "39740.32",
    "39486.73",
    "39469.29",
    "40458.31",
    "38117.46",
    "39241.12",
    "39773.83",
    "38609.82",
    "37714.88",
    "38469.09",
    "38529.33",
    "37750.45",
    "39698.37",
    "36575.14",
    "36040.92",
    "35501.95",
    "34059.27",
    "30296.95",
    "31022.91",
    "28936.36",
    "29047.75",
    "29283.10",
    "30101.27",
    "31305.11",
    "29862.92",
    "30425.86",
    "28720.27",
    "30314.33",
    "29200.74",
    "29432.23",
    "30323.72",
    "29098.91",
    "29655.59",
    "29562.36",
    "29267.22",
    "28627.57",
    "28814.90",
    "29445.96",
    "31726.39",
    "31792.31",
    "29799.08",
    "30467.49",
    "29704.39",
    "29832.91",
    "29906.66",
    "31370.67",
    "31155.48",
    "30214.36",
    "30112.00",
    "29083.80",
    "28360.81",
    "26762.65",
    "22487.39",
    "22206.79",
    "22572.84",
    "20381.65",
    "20471.48",
    "19017.64",
    "20553.27",
    "20599.54",
    "20710.60",
    "19987.03",
    "21085.88",
    "21231.66",
    "21502.34",
    "21027.29",
    "20735.48",
    "20280.63",
    "20104.02",
    "19784.73",
    "19269.37",
    "19242.26",
    "19297.08",
    "20231.26",
    "20190.12",
    "20548.25",
    "21637.59",
    "21731.12",
    "21592.21",
    "20860.45",
    "19970.56",
    "19323.91",
    "20212.07",
    "20569.92",
    "20836.33",
    "21190.32",
    "20779.34",
    "22485.69",
    "23389.43",
    "23231.73",
    "23164.63",
    "22714.98",
    "22465.48",
    "22609.16",
    "21361.70",
    "21239.75",
    "22930.55",
    "23843.89",
    "23804.63",
    "23656.21",
    "23336.90",
    "23314.20",
    "22978.12",
    "22846.51",
    "22630.96",
    "23289.31",
    "22961.28",
    "23175.89",
    "23809.49",
    "23164.32",
    "23947.64",
    "23957.53",
    "24402.82",
    "24424.07",
    "24319.33",
    "24136.97",
    "23883.29",
    "23336.00",
    "23212.74",
    "20877.55",
    "21166.06",
    "21534.12",
    "21398.91",
    "21528.09",
    "21395.02",
    "21600.90",
    "20260.02",
    "20041.74",
    "19616.81",
    "20297.99",
    "19796.81",
    "20049.76",
    "20127.14",
    "19969.77",
    "19832.09",
    "19986.71",
    "19812.37",
    "18837.67",
    "19290.32",
    "19329.83",
    "21381.15",
    "21680.54",
    "21769.26",
    "22370.45",
    "20296.71",
    "20241.09",
    "19701.21",
    "19772.58",
    "20127.58",
    "19419.51",
    "19544.13",
    "18890.79",
    "18547.40",
    "19413.55",
    "19297.64",
    "18937.01",
    "18802.10",
    "19222.67",
    "19110.55",
    "19426.72",
    "19573.05",
    "19431.79",
    "19312.10",
    "19044.11",
    "19623.58",
    "20336.84",
    "20160.72",
    "19955.44",
    "19546.85",
    "19416.57",
    "19446.43",
    "19141.48",
    "19051.42",
    "19157.45",
    "19382.90",
    "19185.66",
    "19067.63",
    "19268.09",
    "19550.76",
    "19334.42",
    "19139.54",
    "19053.74",
    "19172.47",
    "19208.19",
    "19567.01",
    "19345.57",
    "20095.86",
    "20770.44",
    "20285.84",
    "20595.35",
    "20818.48",
    "20635.60",
    "20495.77",
    "20485.27",
    "20159.50",
    "20209.99",
    "21147.23",
    "21282.69",
    "20926.49",
    "20602.82",
    "18541.27",
    "15880.78",
    "17586.77",
    "17034.29",
    "16799.19",
    "16353.37",
    "16618.20",
    "16884.61",
    "16669.44",
    "16687.52",
    "16697.78",
    "16711.55",
    "16291.83",
    "15787.28",
    "16189.77",
    "16610.71",
    "16604.46",
    "16521.84",
    "16464.28",
    "16444.63",
    "16217.32",
    "16444.98",
    "17168.57",
    "16967.13",
    "17088.66",
    "16908.24",
    "17130.49",
    "16974.83",
    "17089.50",
    "16848.13",
    "17233.47",
    "17133.15",
    "17128.72",
    "17104.19",
    "17206.44",
    "17781.32",
    "17815.65",
    "17364.87",
    "16647.48",
    "16795.09",
    "16757.98",
    "16439.68",
    "16906.30",
    "16817.54",
    "16830.34",
    "16796.95",
    "16847.76",
    "16841.99",
    "16919.80",
    "16717.17",
    "16552.57",
    "16642.34",
    "16602.59",
    "16547.50",
    "16625.08",
    "16688.47",
    "16679.86",
    "16863.24",
    "16836.74",
    "16951.97",
    "16955.08",
    "17091.14",
    "17196.55",
    "17446.29",
    "17934.90",
    "18869.59",
    "19909.57",
    "20976.30",
    "20880.80",
    "21169.63",
    "21161.52",
    "20688.78",
    "21086.79",
    "22676.55",
    "22777.63",
    "22720.42",
    "22934.43",
    "22636.47",
    "23117.86",
    "23032.78",
    "23078.73",
    "23031.09",
    "23774.57",
    "22840.14",
    "23139.28",
    "23723.77",
    "23471.87",
    "23449.32",
    "23331.85",
    "22955.67",
    "22760.11",
    "23264.29",
    "22939.40",
    "21819.04",
    "21651.18",
    "21870.88",
    "21788.20",
    "21808.10",
    "22220.80",
    "24307.84",
    "23623.47",
    "24565.60",
    "24641.28",
    "24327.64",
    "24829.15",
    "24436.35",
    "24188.84",
    "23947.49",
    "23198.13",
    "23175.38",
    "23561.21",
    "23522.87",
    "23147.35",
    "23646.55",
    "23475.47",
    "22362.68",
    "22353.35",
    "22435.51",
    "22429.76",
    "22219.77",
    "21718.08",
    "20363.02",
    "20187.24",
    "20632.41",
    "22163.95",
    "24197.53",
    "24746.07",
    "24375.96",
    "25052.79",
    "27423.93",
    "26965.88",
    "28038.68",
    "27767.24",
    "28175.82",
    "27307.44",
    "28333.97",
    "27493.29",
    "27494.71",
    "27994.33",
    "27139.89",
    "27268.13",
    "28348.44",
    "28033.56",
    "28478.48",
    "28411.04",
    "28199.31",
    "27790.22",
    "28168.09",
    "28177.98",
    "28044.14",
    "27925.86",
    "27947.79",
    "28333.05",
    "29652.98",
    "30235.06",
    "30139.05",
    "30399.07",
    "30485.70",
    "30318.50",
    "30315.36",
    "29445.04",
    "30397.55",
    "28822.68",
    "28245.99",
    "27276.91",
    "27817.50",
    "27591.38",
    "27525.34",
    "28307.60",
    "28422.70",
    "29473.79",
    "29340.26",
    "29248.49",
    "29268.81",
    "28091.57",
    "28680.54",
    "29006.31",
    "28847.71",
    "29534.38",
    "28904.62",
    "28454.98",
    "27694.27",
    "27658.78",
    "27621.76",
    "27000.79",
    "26804.99",
    "26784.08",
    "26930.64",
    "27192.69",
    "27036.65",
    "27398.80",
    "26832.21",
    "26890.13",
    "27129.59",
    "26753.83",
    "26851.28",
    "27225.73",
    "26334.82",
    "26476.21",
    "26719.29",
    "26868.35",
    "28085.65",
    "27745.88",
    "27702.35",
    "27219.66",
    "26819.97",
    "27249.59",
    "27075.13",
    "27119.07",
    "25760.10",
    "27238.78",
    "26346.00",
    "26508.22",
    "26480.38",
    "25851.24",
    "25940.17",
    "25902.50",
    "25918.73",
    "25124.68",
    "25576.39",
    "26327.46",
    "26510.68",
    "26336.21",
    "26851.03",
    "28327.49",
    "30027.30",
    "29912.28",
    "30695.47",
    "30548.70",
    "30480.26",
    "30271.13",
    "30688.16",
    "30086.25",
    "30445.35",
    "30477.25",
    "30590.08",
    "30620.77",
    "31156.44",
    "30777.58",
    "30514.17",
    "29909.34",
    "30342.27",
    "30292.54",
    "30171.23",
    "30414.47",
    "30620.95",
    "30391.65",
    "31476.05",
    "30334.07",
    "30295.81",
    "30249.13",
    "30145.89",
    "29856.56",
    "29913.92",
    "29792.02",
    "29908.74",
    "29771.80",
    "30084.54",
    "29176.92",
    "29227.39",
    "29354.97",
    "29210.69",
    "29319.25",
    "29356.92",
    "29275.31",
    "29230.11",
    "29675.73",
    "29151.96",
    "29178.68",
    "29074.09",
    "29042.13",
    "29041.86",
    "29180.58",
    "29765.49",
    "29561.49",
    "29429.59",
    "29397.71",
    "29415.96",
    "29282.91",
    "29408.44",
    "29170.35",
    "28701.78",
    "26664.55",
    "26049.56",
    "26096.21",
    "26189.58",
    "26124.14",
    "26031.66",
    "26431.64",
    "26162.37",
    "26047.67",
    "26008.46",
    "26089.69",
    "26106.15",
    "27727.39",
    "27297.27",
    "25931.47",
    "25800.72",
    "25868.80",
    "25969.57",
    "25812.42",
    "25779.98",
    "25753.24",
    "26240.20",
    "25905.65",
    "25895.68",
    "25832.23",
    "25162.65",
    "25833.34",
    "26228.32",
    "26539.67",
    "26608.69",
    "26568.28",
    "26534.19",
    "26754.28",
    "27211.12",
    "27132.01",
    "26567.63",
    "26579.57",
    "26579.39",
    "26256.83",
    "26298.48",
    "26217.25",
    "26352.72",
    "27021.55",
    "26911.72",
    "26967.92",
    "27983.75",
    "27530.79",
    "27429.98",
    "27799.39",
    "27415.91",
    "27946.60",
    "27968.84",
    "27935.09",
    "27583.68",
    "27391.02",
    "26873.32",
    "26756.80",
    "26862.38",
    "26861.71",
    "27159.65",
    "28519.47",
    "28415.75",
    "28328.34",
    "28719.81",
    "29682.95",
    "29918.41",
    "29993.90",
    "33086.23",
    "33901.53",
    "34502.82",
    "34156.65",
    "33909.80",
    "34089.57",
    "34538.48",
    "34502.36",
    "34667.78",
    "35437.25",
    "34938.24",
    "34732.32",
    "35082.20",
    "35049.36",
    "35037.37",
    "35443.56",
    "35655.28",
    "36693.13",
    "37313.97",
    "37138.05",
    "37054.52",
    "36502.36",
    "35537.64",
    "37880.58",
    "36154.77",
    "36596.68",
    "36585.70",
    "37386.55",
    "37476.96",
    "35813.81",
    "37432.34",
    "37289.62",
    "37720.28",
    "37796.79",
    "37479.12",
    "37254.17",
    "37831.09",
    "37858.49",
    "37712.75",
    "38688.75",
    "39476.33",
    "39978.39",
    "41980.10",
    "44080.65",
    "43746.45",
    "43292.66",
    "44166.60",
    "43725.98",
    "43779.70",
    "41243.83",
    "41450.22",
    "42890.74",
    "43023.97",
    "41929.76",
    "42240.12",
    "41364.66",
    "42623.54",
    "42270.53",
    "43652.25",
    "43869.15",
    "43997.90",
    "43739.54",
    "43016.12",
    "43613.14",
    "42520.40",
    "43442.86",
    "42627.86",
    "42099.40",
    "42156.90",
    "42265.19",
    "44167.33",
    "44957.97",
    "42848.18",
    "44179.92",
    "44162.69",
    "43989.20",
    "43943.10",
    "46970.50",
    "46139.73",
    "46627.78",
    "46368.59",
    "42853.17",
    "42842.38",
    "41796.27",
    "42511.97",
    "43154.95",
    "42742.65",
    "41262.06",
    "41618.41",
    "41665.59",
    "41545.79",
    "39507.37",
    "39845.55",
    "40077.07",
    "39933.81",
    "41816.87",
    "42120.05",
    "42035.59",
    "43288.25",
    "42952.61",
    "42582.61",
    "43075.77",
    "43185.86",
    "42992.25",
    "42583.58",
    "42658.67",
    "43084.67",
    "44318.22",
    "45301.57",
    "47147.20",
    "47771.28",
    "48293.92",
    "49958.22",
    "49742.44",
    "51826.70",
    "51938.55",
    "52160.20",
    "51663.00",
    "52122.55",
    "51779.14",
    "52284.88",
    "51839.18",
    "51304.97",
    "50731.95",
    "51571.10",
    "51733.24",
    "54522.40",
    "57085.37",
    "62504.79",
    "61198.38",
    "62440.63",
    "62029.85",
    "63167.37",
    "68330.41",
    "63801.20",
    "66106.80",
    "66925.48",
    "68300.09",
    "68498.88",
    "69019.79",
    "72123.91",
    "71481.29",
    "73083.50",
    "71396.59",
    "69403.77",
    "65315.12",
    "68390.63",
    "67548.59",
    "61912.77",
    "67913.67",
    "65491.39",
    "63778.76",
    "64062.20",
    "67234.17",
    "69958.81",
    "69987.84",
    "69455.34",
    "70744.95",
    "69892.83",
    "69645.30",
    "71333.65",
    "69702.15",
    "65446.97",
    "65980.81",
    "68508.84",
    "67837.64",
    "68896.11",
    "69362.55",
    "71631.36",
    "69139.02",
    "70587.88",
    "70060.61",
    "67195.87",
    "63821.47",
    "65738.73",
    "63426.21",
    "63811.86",
    "61276.69",
    "63512.75",
    "63843.57",
    "64994.44",
    "64926.64",
    "66837.68",
    "66407.27",
    "64276.90",
    "64481.71",
    "63755.32",
    "63419.14",
    "63113.23",
    "63841.12",
    "60636.86",
    "58254.01",
    "59123.43",
    "62889.84",
    "63891.47",
    "64031.13",
    "63161.95",
    "62334.82",
    "61187.94",
    "63049.96",
    "60792.78",
    "60793.71",
    "61448.39",
    "62901.45",
    "61552.79",
    "66267.49",
    "65231.58",
    "67051.88",
    "66940.80",
    "66278.37",
    "71448.20",
    "70136.53",
    "69122.34",
    "67929.56",
    "68526.10",
    "69265.95",
    "68518.09",
    "69394.55",
    "68296.22",
    "67578.09",
    "68364.99",
    "67491.41",
    "67706.94",
    "67751.60",
    "68804.78",
    "70567.77",
    "71082.82",
    "70757.17",
    "69342.59",
    "69305.77",
    "69647.99",
    "69512.28",
    "67332.03",
    "68241.18",
    "66756.40",
    "66011.09",
    "66191.00",
    "66639.05",
    "66490.29",
    "65140.74",
    "64960.30",
    "64828.66",
    "64096.20",
    "64252.58",
    "63180.80",
    "60277.41",
    "61804.64",
    "60811.28",
    "61604.80",
    "60320.14",
    "60887.38",
    "62678.29",
    "62851.98",
    "62029.02",
    "60173.92",
    "56977.70",
    "56662.37",
    "58303.54",
    "55849.11",
    "56705.10",
    "58009.23",
    "57742.49",
    "57344.91",
    "57899.47",
    "59231.95",
    "60787.79",
    "64870.15",
    "65097.15",
    "64118.79",
    "63974.07",
    "66710.15",
    "67163.64",
    "68154.52",
    "67585.25",
    "65927.67",
    "65372.13",
    "65777.22",
    "67912.06",
    "67813.34",
    "68255.87",
    "66819.92",
    "66201.02",
    "64619.25",
    "65357.50",
    "61415.06",
    "60680.09",
    "58116.98",
    "53991.46",
    "56034.32",
    "55027.46",
    "61710.14",
    "60880.11",
    "60945.81",
    "58719.48",
    "59354.51",
    "60609.57",
    "58737.27",
    "57560.10",
    "58894.10",
    "59478.97",
    "58483.96",
    "59493.45",
    "59012.79",
    "61175.19",
    "60381.91",
    "64094.36",
    "64178.99",
    "64333.54",
    "62880.66",
    "59504.13",
    "59027.63",
    "59388.18",
    "59119.47",
    "58969.90",
    "57325.49",
    "59112.48",
    "57431.02",
    "57971.54",
    "56160.49",
    "53948.75",
    "54139.69",
    "54841.57",
    "57019.53",
    "57648.71",
    "57343.17",
    "58127.01",
    "60571.30",
    "60005.12",
    "59182.84",
    "58192.51",
    "60308.54",
    "61649.68",
    "62940.46",
    "63192.98",
    "63394.84",
    "63648.71",
    "63329.80",
    "64301.97",
    "63143.15",
    "65181.02",
    "65790.66",
    "65887.65",
    "65635.30",
    "63329.50",
    "60837.01",
  ],
};
