import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const LogoUSDX: React.FC<IconProps> = ({
  width = "178px",
  height = "23px",
}) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="179"
        height="24"
        viewBox="0 0 179 24"
        fill="none"
      >
        <path
          d="M165.785 23.625V20.75H173.335C174.335 20.75 174.835 20.2417 174.835 19.225V18H167.11C165.643 18 164.568 17.6333 163.885 16.9C163.218 16.1667 162.885 15.2167 162.885 14.05V5.125H166.51V13.625C166.51 14.625 167.01 15.125 168.01 15.125H174.71V5.125H178.46V19.675C178.46 20.8583 178.118 21.8083 177.435 22.525C176.751 23.2583 175.685 23.625 174.235 23.625H165.785Z"
          fill="white"
        />
        <path
          d="M149.856 18.125C147.623 18.125 145.881 17.575 144.631 16.475C143.381 15.3583 142.756 13.725 142.756 11.575C142.756 9.40833 143.381 7.775 144.631 6.675C145.881 5.55833 147.623 5 149.856 5H156.106C157.573 5 158.689 5.35 159.456 6.05C160.239 6.73333 160.631 7.71667 160.631 9C160.631 11.6167 159.123 12.925 156.106 12.925H146.656C147.006 14.475 148.073 15.25 149.856 15.25H159.981V18.125H149.856ZM149.856 7.875C148.139 7.875 147.081 8.6 146.681 10.05H155.931C156.264 10.05 156.523 9.95 156.706 9.75C156.906 9.55 157.006 9.275 157.006 8.925C157.006 8.225 156.648 7.875 155.931 7.875H149.856Z"
          fill="white"
        />
        <path
          d="M125.189 18V5.125H136.414C137.881 5.125 138.948 5.49167 139.614 6.225C140.298 6.94167 140.639 7.89167 140.639 9.075V18H137.014V9.5C137.014 8.5 136.514 8 135.514 8H128.814V18H125.189Z"
          fill="white"
        />
        <path
          d="M111.208 18.125C108.975 18.125 107.233 17.575 105.983 16.475C104.733 15.3583 104.108 13.725 104.108 11.575C104.108 9.40833 104.733 7.775 105.983 6.675C107.233 5.55833 108.975 5 111.208 5H115.833C118.067 5 119.808 5.55833 121.058 6.675C122.308 7.775 122.933 9.40833 122.933 11.575C122.933 13.725 122.308 15.3583 121.058 16.475C119.808 17.575 118.067 18.125 115.833 18.125H111.208ZM111.208 15.25H115.833C116.917 15.25 117.742 14.95 118.308 14.35C118.892 13.75 119.183 12.825 119.183 11.575C119.183 10.3083 118.892 9.375 118.308 8.775C117.742 8.175 116.917 7.875 115.833 7.875H111.208C110.125 7.875 109.292 8.175 108.708 8.775C108.142 9.375 107.858 10.3083 107.858 11.575C107.858 12.825 108.142 13.75 108.708 14.35C109.292 14.95 110.125 15.25 111.208 15.25Z"
          fill="white"
        />
        <path
          d="M81.0488 18V5.125H97.7238C99.1905 5.125 100.265 5.49167 100.949 6.225C101.632 6.94167 101.974 7.89167 101.974 9.075V18H98.3488V9.525C98.3488 8.50833 97.8322 8 96.7988 8H93.3238V18H89.6988V8H84.6738V18H81.0488Z"
          fill="white"
        />
        <path
          d="M74.1182 17.9998V14.4248H78.1182V17.9998H74.1182Z"
          fill="white"
        />
        <path
          d="M54.582 18.0004L59.432 11.5754L54.582 5.15039H58.832L62.557 10.1254H64.382L68.132 5.15039H72.357L67.532 11.5754L72.357 18.0004H68.132L64.357 13.0004H62.557L58.832 18.0004H54.582Z"
          fill="white"
        />
        <path
          d="M52.9535 18H49.3285V0.125H52.9535V18ZM52.3285 18H43.7035C41.4702 18 39.7285 17.45 38.4785 16.35C37.2285 15.25 36.6035 13.6583 36.6035 11.575C36.6035 9.475 37.2285 7.875 38.4785 6.775C39.7285 5.675 41.4702 5.125 43.7035 5.125H52.0785V8H43.7035C42.6202 8 41.7868 8.3 41.2035 8.9C40.6368 9.48333 40.3535 10.375 40.3535 11.575C40.3535 12.7583 40.6368 13.65 41.2035 14.25C41.7868 14.8333 42.6202 15.125 43.7035 15.125H52.3285V18Z"
          fill="white"
        />
        <path
          d="M18.0506 18.125V15.25H30.3006C31.0006 15.25 31.3506 14.8583 31.3506 14.075C31.3506 13.3083 31.0006 12.925 30.3006 12.925H22.3756C20.8923 12.925 19.7673 12.5917 19.0006 11.925C18.2339 11.2417 17.8506 10.2667 17.8506 9C17.8506 7.71667 18.2339 6.73333 19.0006 6.05C19.7839 5.35 20.9089 5 22.3756 5H33.7756V7.875H22.5506C21.8339 7.875 21.4756 8.225 21.4756 8.925C21.4756 9.275 21.5756 9.55 21.7756 9.75C21.9756 9.95 22.2339 10.05 22.5506 10.05H30.9506C32.2506 10.05 33.2423 10.3917 33.9256 11.075C34.6256 11.7417 34.9756 12.7167 34.9756 14C34.9756 15.3 34.6256 16.3167 33.9256 17.05C33.2256 17.7667 32.2339 18.125 30.9506 18.125H18.0506Z"
          fill="white"
        />
        <path
          d="M4.63125 18C3.16458 18 2.08958 17.6333 1.40625 16.9C0.739583 16.1667 0.40625 15.2167 0.40625 14.05V5.125H4.03125V13.625C4.03125 14.625 4.53125 15.125 5.53125 15.125H10.7313C11.7313 15.125 12.2312 14.625 12.2312 13.625V5.125H15.8563V14.05C15.8563 15.2167 15.5146 16.1667 14.8313 16.9C14.1646 17.6333 13.0979 18 11.6313 18H4.63125Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default LogoUSDX;
